import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { Content } from "./Content";
import { ReactComponent as BANK_ICON } from "../../Images/GlobalHeader/BANK_ICON.svg";
import { ReactComponent as DOWNLOAD_ICON } from "../../Images/GlobalHeader/DOWNLOAD_ICON.svg";
import { useState, useEffect } from "react";
import "../../Styles/FormStyles.scss";
import { updateSidebarStatus } from "../../Redux/Slices/leftMenu";
import { useDispatch, useSelector } from "react-redux";
import { FINANCE_REPRESENTATIVE_URL } from "../../Constants/Constants";

function IntroductionPage() {
  const { userStatus } = useSelector((state) => state.userInfo);
  const [isUnauthorized, setisUnauthorized] = useState(false);

  // Control the authorization of the page if accessed directly from URL
  useEffect(() => {
    if (userStatus !== "AddBank") setisUnauthorized(true);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNav = () => {
    dispatch(
      updateSidebarStatus({
        menuItem: 1,
        isComplete: true,
      })
    );
    navigate(FINANCE_REPRESENTATIVE_URL);
  };
  return (
    <>
      <GlobalHeader />

      {isUnauthorized ? (
        <div> You are not authorized on this page</div>
      ) : (
        <div className="d-flex flex-row">
          <div className="container-fluid ps-0 pe-5">
            <div className="row g-3 g-sm-0 d-flex justify-content-left">
              <div className="col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container ">
                {/* <Sidebar activeIndex={1} /> */}
              </div>

              <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight">
                <Breadcrumb innerPage="Introduction" />

                <div className="row g-3 g-sm-0 d-flex justify-content-left">
                  <div className="col-sm-8 gx-0 p-3 gx-sm-4">
                    <h1
                      aria-hidden="true"
                      className="fs-5 mb-3 text-pp-secondary"
                    >
                      {Content.IntroductionPage.title}
                    </h1>
                    <p className="fs-6">
                      {Content.IntroductionPage.paragraphOne}
                    </p>
                    <p className="fs-6">
                      {Content.IntroductionPage.paragraphTwo}
                    </p>
                    <div className="card">
                      <div className="px-2 ms-1 pt-3">
                        <p className="mb-3 text-dark">
                          <span className="me-2 pb-1">
                            <BANK_ICON />
                          </span>
                          The following information is required to complete
                          Banking Information forms:
                        </p>
                        <hr className="border-1 mb-2 me-auto"></hr>
                        <div className="card-body p-0">
                          <ul className="fw-semibold">
                            <li>
                              Find Your Org ( Email sent to point of contact)
                            </li>
                            <li>Representative Information</li>
                            <li>Entity Details</li>
                            <li>Tax Information</li>
                            <li>Banking Information</li>
                            <li>W-9 form</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3 g-sm-0 d-flex justify-content-left">
                    <div className="col-sm-8 gx-0 px-3 pt-2 gx-sm-4">
                      <div>
                        <p className="fs-6 mb-2">
                          For assistance in completing this form, please contact
                          our team at:
                        </p>
                        <a
                          aria-label="Cited Email Link"
                          href="mailto:finance@ca-path.com "
                        >
                          finance@ca-path.com
                        </a>{" "}
                        <span> or </span>
                        <span className="fw-semibold"> (866) 529-7550</span>
                      </div>
                      <div className="py-4">
                        <p className="fs-6 mb-2">
                          Please download the latest W-9 form for your records.
                        </p>
                        <a
                          aria-label="irs doc Link"
                          href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
                          className="text-decoration-none"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DOWNLOAD_ICON /> Download IRS W-9
                        </a>
                        <div className="pt-1 mb-4">
                          <button
                            type="button"
                            className="btn btn-pp-secondary mt-4"
                            onClick={handleNav}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default IntroductionPage;
