import ScrollArea from "../../../Elements/ScrollArea/ScrollArea";

/**
 * Specific Implementation of ScrollArea.js for use on the Submission and Review Page of the TPA-Public-Portal
 * Protected Finance Form.
 * @returns {JSX.Element}
 * @constructor
 */
function SubmitScrollArea() {
  return (
    <ScrollArea width={528} height={421} className="fs-8 py-3 pe-2 ps-1">
      <span>
        As a condition of receiving funds distributed by the State of
        California, Department of Health Care Services (DHCS), through its
        contracted fiscal intermediary, Public Consulting Group LLC (PCG), on
        behalf of the below listed entity, I agree to the following and any
        applicable federal or state statutes and regulations associated with the
        funding received:
      </span>
      <br />
      <span>
        I am an agent of the entity applying to receive payments for a DHCS
        PATH initiative and am authorized by the entity to complete and
        sign this attestation on its behalf. I authorize PCG to initiate ACH
        deposits to the bank account provided.{" "}
      </span>
      <br />
      <span>
        The entity agrees that it is fully responsible for any and all tax
        consequences as a result of receiving DHCS payments and does not rely on
        anything that the State of California, DHCS, or PC, state about this
        issue.
      </span>
      <br />
      <span>
        The entity agrees that the bank account information that it provides to
        receive the DHCS payments is accurate and is the account used by the
        entity for payment of business expenses. The entity agrees that it will
        not hold the State of California, DHCS, or PCG, responsible if the
        incorrect bank account information is provided and the DHCS funds are
        transferred to such account; the State of California, DHCS, and PCG,
        will accept the bank account information &apos;as is.&apos;
      </span>
      <br />
      <span>
        The entity agrees it may be subject to federal or state reporting and/or
        auditing requirements. It agrees to maintain and report detailed
        financial information, as required, and to make its staff available to
        answer any questions or provide any documents about the DHCS payments
        and how it used the payments, upon request by any agency of the State of
        California, including DHCS or any other government agency at any time.
      </span>
      <br />
      <span>
        The entity agrees that it may receive DHCS payments while simultaneously
        receiving funding from other sources, including but not limited to
        federal or state programs. But the entity agrees that it cannot use the
        DHCS payments for expenses that are reimbursed or paid for by other
        federal or state programs or agencies. As a result, the entity is solely
        and exclusively responsible for abiding by all applicable terms,
        conditions, rules, and regulations concerning the receipt of federal or
        state payments. In addition, the entity will not hold the State of
        California, DHCS, or PC, responsible in any way if the receipt of DHCS
        payments is prohibited or restricted as a result of other funding that
        the entity has received.
      </span>
    </ScrollArea>
  );
}

export default SubmitScrollArea;
