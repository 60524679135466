import axios from "axios";
import {
  SUBMIT_API,
  FINANCE_W9_UPLOAD_URL,
} from "../../../Constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { resetBankingInfo } from "../../../Redux/Slices/bankingInfo";
import { resetLeftMenu } from "../../../Redux/Slices/leftMenu";

function SubmitNavButton({
  isAttested,
  setIsLoading,
  setResponse,
  signature,
  errors,
}) {
  const { formInfo, fileInfo } = useSelector((state) => state.bankingInfo);
  const { userProfile } = useSelector((state) => state.userInfo);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const saveInfo = () => {
  //   const updatedObj = {
  //     esign: signature,
  //   };
  //   dispatch(updateInfo(updatedObj));
  // };

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    // saveInfo();
    const formObj = new FormData();
    const blobconfig = { responseType: "blob" };
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    axios
      .get(fileInfo.w9fileURL, blobconfig)
      .then((response) => {
        const unpackFile = new File([response.data], formInfo.w9fileName);

        // Append all the entries to the form
        Object.entries(formInfo).map(([k, v]) => formObj.append(k, v));

        formObj.append("W9file", unpackFile);
        // formObj.append("username ", user.email);
        formObj.append("w9fileName", fileInfo.w9fileName);
        formObj.append("userName ", userProfile.email);
        formObj.append("esign ", signature);

        axios
          .put(SUBMIT_API, formObj, config)
          .then((response) => {
            setIsLoading(false);
            setResponse(response.data);
          })
          .catch((error) => {
            setIsLoading(false);
            console.error(error);
            setResponse(error.response);
          })
          .finally(() => {
            dispatch(resetBankingInfo());
            dispatch(resetLeftMenu());
          });
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
        setResponse({ status: -1 });
        dispatch(resetBankingInfo());
        dispatch(resetLeftMenu());
      });
  }

  const navigateBack = () => {
    navigate(FINANCE_W9_UPLOAD_URL);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-pp-secondary-outline me-4"
        onClick={navigateBack}
      >
        Previous
      </button>
      <button
        type="submit"
        className="btn btn-pp-secondary"
        disabled={signature.length === 0 || !isAttested || errors}
        onClick={handleSubmit}
      >
        Submit
      </button>
    </>
  );
}

export default SubmitNavButton;
