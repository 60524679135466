import { WarningIcon } from "../../Images/FindYourOrg/warning";

export const InfoArea = () => {
  return (
    <>
      {" "}
      <div
        className="mt-3 mb-3 pt-3 pb-3 row"
        style={{
          minHeight: "1rem",
          backgroundColor: "#FDF3D1",
        }}
      >
        <span className="my-auto text-center col">
          <WarningIcon color="#635019" />
        </span>
        <span className="col-11">
          <span>
            If this is not your organization, please contact our team at:{" "}
            <a
              href="mailto:finance@ca-path.com"
              target="_blank"
              rel="noreferrer"
            >
              finance@ca-path.com
            </a>{" "}
            or call us at{" "}
            <a aria-label="Phone Link" href="tel:(866) 529-7550">
              (866) 529-7550
            </a>{" "}
            .
          </span>
        </span>
      </div>
    </>
  );
};
