import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_USER_BANK_INFO,
  PENNY_TEST_VERIFICATION,
} from "../../Constants/Constants";

const initialState = {
  getFinanceInfoStatus: "idle",
  verifyFinanceInfoStatus: "idle",
  financialInfo: {
    transactionOne: "",
    transactionTwo: "",
    bankNum: "",
    bankAcctHldrName: "",
    bankRoutingNum: "",
    bankInfoIdentifier: "",
  },
};

export const getFinanceInfo = createAsyncThunk(
  "users/getFinanceInfo",

  async (userobj, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        GET_USER_BANK_INFO + userobj.userEmail + "/" + userobj.rcrdIdentif
      );
      return response.data;
    } catch (err) {
      // Return empty value for rejection
      return rejectWithValue({});
    }
  }
);

export const verifyFinanceInfo = createAsyncThunk(
  "users/verifyFinanceInfo",
  async (pennyInfo, { rejectWithValue }) => {
    try {
      const response = await axios.post(PENNY_TEST_VERIFICATION, {
        ...pennyInfo,
      });
      return response.data;
    } catch (err) {
      // Return empty value for rejection
      return rejectWithValue({});
    }
  }
);

const financeInfoSlice = createSlice({
  name: "financeInfo",
  initialState,
  reducers: {
    updateFinanceInfo: (state, action) => {
      state.formInfo = { ...state.formInfo, ...action.payload };
    },
    resetFinanceInfo: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFinanceInfo.fulfilled, (state, action) => {
        state.financialInfo = { ...state.financialInfo, ...action.payload };
        state.getFinanceInfoStatus = "idle";
      })
      .addCase(getFinanceInfo.rejected, (state) => {
        state.getFinanceInfoStatus = "idle";
      })
      .addCase(getFinanceInfo.pending, (state) => {
        state.getFinanceInfoStatus = "loading";
      })
      .addCase(verifyFinanceInfo.pending, (state) => {
        state.verifyFinanceInfoStatus = "loading";
      })
      .addCase(verifyFinanceInfo.fulfilled, (state, action) => {
        state.userStatus = { ...state.user, ...action.payload };
        state.verifyFinanceInfoStatus = "idle";
      })
      .addCase(verifyFinanceInfo.rejected, (state) => {
        state.apiError = true;
        state.verifyFinanceInfoStatus = "idle";
      });
  },
});

export const { updateFinanceInfo, resetFinanceInfo } = financeInfoSlice.actions;
export default financeInfoSlice.reducer;
