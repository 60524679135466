export const EntityIcon = (props) => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7629 0.0746751C14.9103 0.165778 15 0.326712 15 0.500001V15.5C15 15.7761 14.7761 16 14.5 16H11.5C11.2239 16 11 15.7761 11 15.5V14H10V15.5C10 15.7761 9.77614 16 9.5 16H0.5C0.223858 16 0 15.7761 0 15.5V10C0 9.78479 0.137715 9.59372 0.341886 9.52566L6 7.63962V4.5C6 4.31061 6.107 4.13748 6.27639 4.05279L14.2764 0.0527869C14.4314 -0.0247102 14.6155 -0.0164282 14.7629 0.0746751ZM6 8.69371L1 10.3604V15H6V8.69371ZM7 15H9V13.5C9 13.2239 9.22386 13 9.5 13H11.5C11.7761 13 12 13.2239 12 13.5V15H14V1.30902L7 4.80902V15Z"
        fill={props.color}
      />
      <path d="M2 11H3V12H2V11Z" fill={props.color} />
      <path d="M4 11H5V12H4V11Z" fill={props.color} />
      <path d="M2 13H3V14H2V13Z" fill={props.color} />
      <path d="M4 13H5V14H4V13Z" fill={props.color} />
      <path d="M8 9H9V10H8V9Z" fill={props.color} />
      <path d="M10 9H11V10H10V9Z" fill={props.color} />
      <path d="M8 11H9V12H8V11Z" fill={props.color} />
      <path d="M10 11H11V12H10V11Z" fill={props.color} />
      <path d="M12 9H13V10H12V9Z" fill={props.color} />
      <path d="M12 11H13V12H12V11Z" fill={props.color} />
      <path d="M8 7H9V8H8V7Z" fill={props.color} />
      <path d="M10 7H11V8H10V7Z" fill={props.color} />
      <path d="M12 7H13V8H12V7Z" fill={props.color} />
      <path d="M8 5H9V6H8V5Z" fill={props.color} />
      <path d="M10 5H11V6H10V5Z" fill={props.color} />
      <path d="M12 5H13V6H12V5Z" fill={props.color} />
      <path d="M12 3H13V4H12V3Z" fill={props.color} />
    </svg>
  );
};
