export const Content = {
  taxInfo: {
    title: "Tax Information",
    subtitle: "Please the tax status for your or your organization.",
    label: "Legal Entity",
    data: [
      {
        Initiatives: ["Select", "Individuals/Sole Proprietorship", "Other"],
      },
    ],
  },
  Options: [
    {
      code: "IN",
      description: "Individual / Sole Proprieteryship",
      type: "SSN",
    },
    {
      code: "SC",
      description: "S Corporation",
      type: "EIN",
    },
    {
      code: "TR",
      description: "Trust / Estate",
      type: "EIN",
    },
    {
      code: "CC",
      description: "C Corporation",
      type: "EIN",
    },
    {
      code: "PA",
      description: "Partnership",
      type: "EIN",
    },
    {
      code: "LL",
      description: "Limited Liability Company",
      type: "EIN",
    },
    {
      code: "OT",
      description: "Other",
      type: "EIN",
    },
  ],
};
