import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Modal } from "../../../node_modules/bootstrap/dist/js/bootstrap.esm.min";
import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import FindOrgSidebar from "../../Components/Sidebar/FindOrgSidebar";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import { Content } from "./Content";
import axios from "axios";
import { USER_CONFIRM_ORG_API } from "../../Constants/Constants.js";
import { useSelector } from "react-redux";
import { LoadingState } from "./LoadingState.js";
import { ErrorState } from "../../Components/ErrorComponent/ErrorState.js";
import { InfoArea } from "./InformationArea.js";

const ConfirmOrg = () => {
  const navigate = useNavigate();
  const cancelRef = useRef();
  const modalRef = useRef();

  const menuNumber = 2;

  const { orgProfile, userProfile } = useSelector((state) => state.userInfo);
  const menuInfo = useSelector((state) => state.leftMenu.menuItems);

  // get the max menu items accessed successfuly by the user
  const max = menuInfo.reduce(
    function (prev, current) {
      return prev.menuItem > current.menuItem ? prev : current;
    },
    { menuItem: 0 }
  );

  const [checked, setChecked] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [error, setisError] = useState(false);
  const [isUnauthorized, setisUnauthorized] = useState();
  const navigateBack = () => {
    navigate("/FindYourOrg");
  };

  const moveNext = () => {
    navigate("/");
  };

  const onChecked = () => {
    setChecked(!checked);
  };

  // check if unauthorized URL access
  if (menuNumber > max?.menuItem + 1 && !isUnauthorized) {
    setisUnauthorized(true);
    // setisUnauthorized(false);
  }

  let bsModal;
  useEffect(() => {
    const myModal = modalRef.current;
    bsModal = Modal.getInstance(myModal);

    if (!bsModal) {
      // initialize Toast
      bsModal = new Modal(myModal);
    }
  });

  const linkAccount = () => {
    setIsloading(true);

    axios
      .post(USER_CONFIRM_ORG_API, {
        orgName: orgProfile.orgName,
        addrLine1: orgProfile.addrLine1,
        addrLine2: orgProfile.addrLine2,
        city: orgProfile.city,
        state: orgProfile.state,
        zip: orgProfile.zip,
        zipPlus4: orgProfile.zipPlus4,
        userName: userProfile.email,
        appIdentifier: orgProfile.appIdentifier,
      })
      .then(function () {
        setIsloading(true);
        bsModal.show();
      })
      .catch(function (error) {
        console.error(error);
        setisError(true);
        setChecked(false);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const getErrorText = () => {
    return (
      <span>
        If this is not your organization, please contact our team at:{" "}
        <a href="mailto:finance@ca-path.com" target="_blank" rel="noreferrer">
          finance@ca-path.com
        </a>{" "}
        or call us at{" "}
        <a aria-label="Phone Link" href="tel:(866) 529-7550">
          (866) 529-7550
        </a>{" "}
        .
      </span>
    );
  };

  return (
    <>
      {/* Todo:Modal Code to a modal component  */}
      <div className="modal" tabIndex="-1" id="myModal" ref={modalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 aria-hidden="true" className="modal-title">
                Congratulations
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                The account has been successfully linked to {orgProfile.orgName}
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn  btn-pp-secondary"
                data-bs-dismiss="modal"
                onClick={moveNext}
              >
                OK
              </button>

              {/* hidden button to cancel the modal background */}
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={cancelRef}
                style={{ display: "none" }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <GlobalHeader />
      <div className="d-flex flex-row">
        <div className="container-fluid ps-0 pe-3">
          <div className="row g-3 g-sm-0 d-flex justify-content-left">
            <div className="col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container grayBackground">
              <FindOrgSidebar activeIndex={3} />
            </div>
            <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight">
              <Breadcrumb innerPage="Confirm Organization" />
              {isUnauthorized ? (
                <div> You are not authorized on this page</div>
              ) : (
                <>
                  <div className="container-fluid">
                    <PageHeader
                      title={Content.EntityDetailsPage.title}
                      isForm={true}
                    >
                      {Content.EntityDetailsPage.subtitle}
                    </PageHeader>

                    {error ? (
                      <ErrorState message={getErrorText()} />
                    ) : (
                      <InfoArea />
                    )}

                    <div>
                      <strong>{orgProfile.orgName}</strong>
                    </div>
                    <div>{orgProfile.addrLine1}</div>
                    <div>{orgProfile.addrLine2}</div>
                    <div>
                      {orgProfile.city}, {orgProfile.state}, {orgProfile.zip}-
                      {orgProfile.zipPlus4}
                    </div>

                    <div className="mt-5">
                      <div className="mb-3 form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="agreementCheck"
                          onChange={onChecked}
                          required
                          checked={checked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="agreementCheck"
                        >
                          {Content.Disclaimer}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div></div>
                    </div>

                    <div></div>

                    <div className="col-12 mb-5">
                      <button
                        type="button"
                        className="btn btn-pp-secondary-outline me-3 mt-4"
                        onClick={navigateBack}
                      >
                        Previous
                      </button>

                      {isloading ? (
                        <LoadingState />
                      ) : (
                        <>
                          <button
                            type="submit"
                            className={
                              checked
                                ? "btn btn-pp-secondary mt-4"
                                : "btn btn-pp-secondary mt-4 disabled"
                            }
                            onClick={linkAccount}
                          >
                            Confirm Organization
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmOrg;
