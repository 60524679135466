import { useState } from "react";
import { ReactComponent as SHOW_PASSWORD } from "../../Images/GlobalHeader/SHOW_PASSWORD.svg";
import { ReactComponent as HIDE_PASSWORD } from "../../Images/GlobalHeader/HIDE_PASSWORD.svg";
function OptionIndividual({ errors, inputsHandler, inputField }) {
  const [isPasswordShow, setIsPasswordShow] = useState([false, false]);
  // const [isSSNMismatch, setisSSNMismatch] = useState(false);

  const toggleIsPasswordShowValue = (item) => {
    const updatedToggle = isPasswordShow.map((ele, i) => {
      return i === item ? !ele : ele;
    });
    setIsPasswordShow(updatedToggle);
  };

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  // const formatSSN = (number) => {
  //   // Filter non numbers
  //   const formattedNumber = number.replace(/[^0-9\\.]+/g, "");
  //   if (formattedNumber.length > 0) {
  //     if (formattedNumber.length < 4) {
  //       return formattedNumber;
  //     }
  //     if (formattedNumber.length > 3 && formattedNumber.length < 6) {
  //       return `${formattedNumber.slice(0, 3)}-${formattedNumber.slice(3, 5)}`;
  //     }
  //     if (formattedNumber.length > 5) {
  //       return `${formattedNumber.slice(0, 3)}-${formattedNumber.slice(
  //         3,
  //         5
  //       )}-${formattedNumber.slice(5, 9)}`;
  //     }
  //   }
  //   return formattedNumber;
  // };
  //
  // const validateSSN = (number) => {
  //   if (number.length === 11 && number !== inputField.SSN && !isSSNMismatch) {
  //     setisSSNMismatch(true);
  //   }
  //   if (number.length < 11 && isSSNMismatch) {
  //     setisSSNMismatch(false);
  //   }
  //   return formatSSN(number);
  // };

  return (
    <>
      <div className="row pt-3">
        <div className="col-md-4">
          <label htmlFor="SSN *" className="form-label mb-2">
            Enter Social Security Number (SSN)
            <span className="text-danger">*</span>
          </label>
          <div className="row">
            <div className="input-group">
              <input
                type={isPasswordShow[0] ? "text" : "password"}
                className={
                  hasError("SSN")
                    ? "form-control  is-invalid "
                    : "form-control "
                }
                name="SSN"
                required
                onChange={inputsHandler}
                value={inputField.SSN}
                maxLength={11}
                autoComplete="-ignore-"
              />
              <div
                onClick={() => toggleIsPasswordShowValue(0)}
                className="input-group-text"
                id="btnGroupAddon"
              >
                {isPasswordShow[0] ? <SHOW_PASSWORD /> : <HIDE_PASSWORD />}
              </div>
              <div className="invalid-feedback">Enter valid SSN</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row pt-3">
        <div className="col-md-4 mb-3">
          <label htmlFor="SSN *" className="form-label mb-2">
            Re-Enter Social Security Number (SSN)
            <span className="text-danger">*</span>
          </label>
          <div className="row">
            <div className="input-group">
              <input
                type={isPasswordShow[1] ? "text" : "password"}
                className={
                  hasError("reEnterSSN")
                    ? "form-control  is-invalid "
                    : "form-control "
                }
                name="reEnterSSN"
                required
                onChange={inputsHandler}
                value={inputField.reEnterSSN}
                maxLength={11}
                autoComplete="-ignore-"
              />

              <div
                onClick={() => toggleIsPasswordShowValue(1)}
                className="input-group-text"
                id="btnGroupAddon"
              >
                {isPasswordShow[1] ? <SHOW_PASSWORD /> : <HIDE_PASSWORD />}
              </div>
              <div className="invalid-feedback">
                Value does not match with SSN
              </div>
            </div>
          </div>
          <p className="text-muted pt-2">Re-enter for verification</p>
        </div>
      </div>
    </>
  );
}

export default OptionIndividual;
