/* eslint-disable quotes */
import {
  Authenticator,
  Heading,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LOGIN_WHITELIST, HOME_URL } from "../../Constants/Constants";
import { Auth } from "aws-amplify";
import axios from "axios";

export function AmplifyLogin() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();

  // From router docs:  lets router load the state from the last location
  const previous = location.state?.from?.pathname;

  // From router docs:  lets router load the state from the last location
  const from =
    (LOGIN_WHITELIST.includes(previous) ? previous : false) || HOME_URL;
  /**
   * whatsMyIp helper
   *  Query's an API for the users IP address to add to a users record on signup.
   * @returns {Promise<AxiosResponse<any>>}
   */
  const whatsMyIp = async () => {
    return await axios
      .get("https://ipapi.co/ip/")
      .then((result) => {
        // Response body is just the IP so return it
        return result.data;
      })
      .catch(() => {
        // If we failed the just set the IP as 0.0.0.0. IPs are captured in app submissions regardless.
        return "0.0.0.0";
      });
  };

  const formFields = {
    signUp: {
      given_name: {
        label: "First Name",
        placeholder: "First Name",
        order: 1,
        isRequired: true,
      },
      family_name: {
        label: "Last Name",
        placeholder: "Last Name",
        order: 2,
        isRequired: true,
      },
      phone_number: {
        label: "Phone Number",
        placeholder: "+18009991111",
        order: 3,
        isRequired: true,
        dialCodeList: ["+1"],
      },
      username: {
        label: "Username",
        placeholder: "Enter your Email",
        order: 4,
        isRequired: true,
      },
      password: {
        label: "Password",
        placeholder: "Enter your Password",
        order: 5,
        isRequired: true,
      },
      confirm_password: {
        label: "Confirm Password",
        placeholder: "Confirm your Password",
        order: 6,
        isRequired: true,
      },
    },
  };

  const components = {
    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <div>
            <Heading
              padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
              level={3}
              formFields={formFields}
            >
              Welcome to PATH TPA Sign-In
            </Heading>
            <p className="text-center pt-4 m-0 fs-5">
              Please sign-in or create an account
            </p>
          </div>
        );
      },
    },
    SignUp: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Create a PATH TPA Account
          </Heading>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            <p className="fs-2">Enter your Multi-Factor Authentication Code</p>
          </Heading>
        );
      },
    },
  };

  const services = {
    async handleSignUp(formData) {
      const { username, password, attributes } = formData;
      const ip = await whatsMyIp();
      return Auth.signUp({
        username,
        password,
        attributes: {
          "custom:IP_Address": `${ip}`,
          given_name: attributes.given_name,
          family_name: attributes.family_name,
          phone_number: attributes.phone_number,
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: true,
        },
      });
    },
  };

  useEffect(() => {
    // If a use is authenticated navigate to the last route the user was in
    if (route === "authenticated") {
      navigate(from, { replace: true });
    } else {
      const authenticator = document.querySelector("[data-amplify-footer]");
      const pTag = document.createElement("p");
      pTag.append(
        "For help signing-in or resetting a password, please refer to the "
      );
      const aTag = document.createElement("a");
      aTag.innerText = "Sign-in and Password Reset";
      aTag.setAttribute("href", process.env.REACT_APP_SIGN_IN_GUIDE_URL);
      aTag.setAttribute("target", "_blank");
      pTag.append(aTag);
      pTag.append(" guide.");
      authenticator?.append(pTag);
    }
  }, [route, navigate, from]);

  return (
    <div className="auth-wrapper pt-3">
      <Authenticator
        services={services}
        components={components}
        formFields={formFields}
      ></Authenticator>
    </div>
  );
}
