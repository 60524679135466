import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { HomeIcon } from "../../Assets/LeftMenu/HomeIcon.js";

import { useState } from "react";
import "./Sidebar.scss";
import CancelNavigation from "../CancelNavigation/CancelNavigation.js";
import SidebarElement from "./SidebarElement.js";

const LandingSidebar = (props) => {
  const activeIndex = props.activeIndex;
  const navigate = useNavigate();

  const [showPop, setShowPop] = useState(null);
  const [navigateTo, setNavigateTo] = useState(null);

  const pageInfo = useSelector((state) => state.leftMenu.menuItems);
  const dirtyPage = pageInfo.find((x) => x.isComplete === false);

  const manageNavigation = (link) => {
    // If updated page show Popup
    if (dirtyPage) {
      setShowPop(true);
      setNavigateTo(link);
    } else {
      navigate(link);
    }
  };

  return (
    <>
      {showPop ? (
        <CancelNavigation
          navLink={navigateTo}
          onCancelClick={() => setShowPop(!showPop)}
          menuItem={activeIndex}
        ></CancelNavigation>
      ) : (
        <> </>
      )}

      <div className="bd-sidebar lightGray">
        <div
          className="offcanvas-lg offcanvas-start "
          tabIndex={-1}
          id="bdSidebar"
          aria-labelledby="bdSidebarOffcanvasLabel"
        >
          <div className="offcanvas-header border-bottom">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              data-bs-target="#bdSidebar"
            ></button>
          </div>

          <div className="offcanvas-body">
            <nav
              className="bd-links w-100"
              id="bd-docs-nav"
              aria-label="Docs navigation"
            >
              <div className=" mt-5 ">
                <SidebarElement
                  icon={HomeIcon}
                  pageInfo={pageInfo}
                  activeIndex={activeIndex}
                  index={1}
                  manageNavigation={manageNavigation}
                  name="Home"
                  navlink="/"
                />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingSidebar;
