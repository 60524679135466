export const ConfirmOrgIcon = (props) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 7C1.5 3.96243 3.96243 1.5 7 1.5C8.00262 1.5 8.94117 1.76782 9.74964 2.2355C9.98867 2.37377 10.2945 2.29209 10.4328 2.05306C10.5711 1.81403 10.4894 1.50816 10.2504 1.36989C9.29379 0.816543 8.1831 0.5 7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 6.72386 13.2761 6.5 13 6.5C12.7239 6.5 12.5 6.72386 12.5 7C12.5 10.0376 10.0376 12.5 7 12.5C3.96243 12.5 1.5 10.0376 1.5 7Z"
        fill="#ADB5BD"
      />
      <path
        d="M14.3536 2.35355C14.5488 2.15829 14.5488 1.84171 14.3536 1.64645C14.1583 1.45118 13.8417 1.45118 13.6464 1.64645L7 8.29289L4.35355 5.64645C4.15829 5.45118 3.84171 5.45118 3.64645 5.64645C3.45118 5.84171 3.45118 6.15829 3.64645 6.35355L6.64645 9.35355C6.84171 9.54882 7.15829 9.54882 7.35355 9.35355L14.3536 2.35355Z"
        fill={props.color}
      />
    </svg>
  );
};
