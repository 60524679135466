export const TaxIcon = (props) => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1C10.5523 1 11 1.44772 11 2V14C11 14.5523 10.5523 15 10 15H2C1.44772 15 1 14.5523 1 14V2C1 1.44772 1.44772 1 2 1H10ZM2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H10C11.1046 16 12 15.1046 12 14V2C12 0.895431 11.1046 0 10 0H2Z"
        fill={props.color}
      />
      <path
        d="M2 2.5C2 2.22386 2.22386 2 2.5 2H9.5C9.77614 2 10 2.22386 10 2.5V4.5C10 4.77614 9.77614 5 9.5 5H2.5C2.22386 5 2 4.77614 2 4.5V2.5Z"
        fill={props.color}
      />
      <path
        d="M2 6.5C2 6.22386 2.22386 6 2.5 6H3.5C3.77614 6 4 6.22386 4 6.5V7.5C4 7.77614 3.77614 8 3.5 8H2.5C2.22386 8 2 7.77614 2 7.5V6.5Z"
        fill={props.color}
      />
      <path
        d="M2 9.5C2 9.22386 2.22386 9 2.5 9H3.5C3.77614 9 4 9.22386 4 9.5V10.5C4 10.7761 3.77614 11 3.5 11H2.5C2.22386 11 2 10.7761 2 10.5V9.5Z"
        fill={props.color}
      />
      <path
        d="M2 12.5C2 12.2239 2.22386 12 2.5 12H3.5C3.77614 12 4 12.2239 4 12.5V13.5C4 13.7761 3.77614 14 3.5 14H2.5C2.22386 14 2 13.7761 2 13.5V12.5Z"
        fill={props.color}
      />
      <path
        d="M5 6.5C5 6.22386 5.22386 6 5.5 6H6.5C6.77614 6 7 6.22386 7 6.5V7.5C7 7.77614 6.77614 8 6.5 8H5.5C5.22386 8 5 7.77614 5 7.5V6.5Z"
        fill={props.color}
      />
      <path
        d="M5 9.5C5 9.22386 5.22386 9 5.5 9H6.5C6.77614 9 7 9.22386 7 9.5V10.5C7 10.7761 6.77614 11 6.5 11H5.5C5.22386 11 5 10.7761 5 10.5V9.5Z"
        fill={props.color}
      />
      <path
        d="M5 12.5C5 12.2239 5.22386 12 5.5 12H6.5C6.77614 12 7 12.2239 7 12.5V13.5C7 13.7761 6.77614 14 6.5 14H5.5C5.22386 14 5 13.7761 5 13.5V12.5Z"
        fill={props.color}
      />
      <path
        d="M8 6.5C8 6.22386 8.22386 6 8.5 6H9.5C9.77614 6 10 6.22386 10 6.5V7.5C10 7.77614 9.77614 8 9.5 8H8.5C8.22386 8 8 7.77614 8 7.5V6.5Z"
        fill={props.color}
      />
      <path
        d="M8 9.5C8 9.22386 8.22386 9 8.5 9H9.5C9.77614 9 10 9.22386 10 9.5V13.5C10 13.7761 9.77614 14 9.5 14H8.5C8.22386 14 8 13.7761 8 13.5V9.5Z"
        fill={props.color}
      />
    </svg>
  );
};
