/* eslint-disable */
export const SUBMIT_API = process.env.REACT_APP_SUBMIT_FINANCE_FORM;
export const EMAIL_VALIDATION =
  /^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]+)$/;
export const CITY_VALIDATION = /^([a-zA-Z]+\s*)+$/;
export const NAME_VALIDATION = /^([a-zA-Z]+\s*-?'?)+$/;
export const ZIP_VALIDATION = /^(\d{1,5})?$/;
export const SNN_EIN_VALIDATION = /^(\d{1,9})?$/;
export const ACCOUNT_VALIDATION = /^(\d{1,17})?$/;
export const FORMATTED_PHONE_VALIDATION =
  /^\(?(\d{0,3})\)?-?(\d{0,3})?-?(\d{0,4})?$/;
export const FILE_NAME_VALIDATION = /^[a-zA-Z0-9!_\-.'()\s]+$/;

export const IS_NUMERIC_VALIDATION = /^(\d)*$/;
// export const FORMAT_SSN = /(\d{3})(\d{2})(\d{4})/;
// export const SSN_FORMAT_VALIDATION = /^(\d{0,3})?-?(\d{0,2})?-?(\d{0,4})?$/;
// export const EIN_FORMAT_VALIDATION = /^(\d{0,3})?-?(\d{0,7})?$/;
export const USER_APP_STATUS_API = process.env.REACT_APP_USER_APP_STATUS_API;
export const FINANCE_FIND_ORGANIZATION = process.env.REACT_APP_FINANCE_FIND_ORG;
export const USER_CONFIRM_ORG_API = process.env.REACT_APP_FINANCE_CONFIRM_ORG;
export const GET_USER_BANK_INFO = process.env.REACT_APP_GET_USER_BANK_INFO;
export const PENNY_TEST_VERIFICATION = process.env.REACT_APP_VERIFY_PENNY_TRANS;

/*===================================================================================
    General URLS
 =================================================================================== */
export const HOME_URL = "/";
export const LOGIN_URL = "/login";
/*===================================================================================
     Verification URLS
 =================================================================================== */

export const FIND_YOUR_ORG_URL = "/FindYourOrg";
export const CONFIRM_ORG_URL = "/ConfirmOrg";
export const FINANCE_VERIFICATION_URL = "/FinancialVerification";

/*===================================================================================
    Fiance Form URLS
 =================================================================================== */
export const FINANCE_INTRODUCTION_URL = "/Introduction";
export const FINANCE_REPRESENTATIVE_URL = "/representative";
export const FINANCE_W9_UPLOAD_URL = "/W-9-form-upload";
export const FINANCE_TAX_INFO_URL = "/tax-info";
export const FINANCE_BANK_INFO_URL = "/bank-info";
export const FINANCE_SUBMIT_URL = "/submit";
export const FINANCE_REVIEW_URL = "/review";
export const FINANCE_ENTITY_DETAIL_URL = "/entitydetails";

/*==============================================================
 * URL Groups
 ==============================================================*/
// list of URLS that you are permitted to route to after login referenced in login.js
export const LOGIN_WHITELIST = [HOME_URL];

/*==============================================================
 * CONSTANTS GENERATOR
 ==============================================================*/
export const IS_LOCALHOST = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
export const removeWhiteSpaces = (value) => value?.trim();
