import { useState } from "react";
import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { Content } from "./Content";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import { updateInfo } from "../../Redux/Slices/bankingInfo";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../Components/Sidebar/Sidebar";
import OptionOther from "./OptionOther";
import OptionIndividual from "./OptionIndividual";
import { useNavigate } from "react-router-dom";
import { updateSidebarStatus } from "../../Redux/Slices/leftMenu";
import CancelNavigation from "../../Components/CancelNavigation/CancelNavigation";
import {
  FINANCE_BANK_INFO_URL,
  FINANCE_ENTITY_DETAIL_URL,
  SNN_EIN_VALIDATION,
} from "../../Constants/Constants";
import useValidNavigation from "../../Elements/useValidNavigationHook/useValidNavigation";

function TaxInfoPage() {
  const menuNumber = 4;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Custom Hook to check if a user can access this page.
  useValidNavigation(FINANCE_ENTITY_DETAIL_URL, menuNumber);
  const pageInfo = useSelector((state) => state.bankingInfo.formInfo);

  const localobj = {
    legalEntity: pageInfo.legalEntity,
    legalEntityOther: pageInfo.legalEntityOther,
    SSN: pageInfo.ssn,
    reEnterSSN: pageInfo.ssn,
    EIN: pageInfo.ein,
    reEnterEIN: pageInfo.ein,
  };

  const [inputField, setInputField] = useState(localobj);
  const [errors, setErrors] = useState([]);
  const [isPageUpdated, setIsPageUpdated] = useState(false);
  const [showCancel, setshowCancel] = useState("");

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (!isPageUpdated) {
      setIsPageUpdated(true);
      dispatch(
        updateSidebarStatus({
          menuItem: 5,
          isComplete: false,
        })
      );
    }

    if (errors.includes(name)) {
      setErrors(errors.filter((ele) => ele !== name));
    }
    switch (name) {
      case "EIN":
        if (value.length > 9 || !value.match(SNN_EIN_VALIDATION)) return;
        break;
      case "SSN":
        if (value.length > 9 || !value.match(SNN_EIN_VALIDATION)) return;
        break;
      case "reEnterSSN":
        if (value.length > 9 || !value.match(SNN_EIN_VALIDATION)) return;
        break;
      case "reEnterEIN":
        if (value.length > 9 || !value.match(SNN_EIN_VALIDATION)) return;
        break;
      case "legalEntity":
        if (value === "OT" || value === "selectEntity") {
          setInputField((previousInputs) => ({
            ...previousInputs,
            ...{ SSN: "", reEnterSSN: "", legalEntityOther: "" },
          }));
        } else if (value === "IN" || value === "selectEntity") {
          setInputField((previousInputs) => ({
            ...previousInputs,
            ...{ EIN: "", reEnterEIN: "" },
          }));
        }
        break;
    }

    setInputField((previousInputs) => ({
      ...previousInputs,
      [name]: value,
    }));
  };

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const taxType = Content.Options.find(
    (ele) => ele.code === inputField.legalEntity
  );

  const saveInfo = () => {
    const errorObj = [];

    if (
      inputField.legalEntity === "" ||
      inputField.legalEntity === "Please Select"
    ) {
      errorObj.push("legalEntity");
    }

    // Selected EIN
    if (taxType?.type === "EIN") {
      if (
        inputField.legalEntity === "OT" &&
        inputField.legalEntityOther === ""
      ) {
        errorObj.push("legalEntityOther");
      }
      if (inputField.EIN.length !== 9) {
        errorObj.push("EIN");
      }

      if (
        inputField.reEnterEIN === "" ||
        inputField.EIN !== inputField.reEnterEIN
      ) {
        errorObj.push("reEnterEIN");
      }
    }
    // Selected SSN
    if (taxType?.type === "SSN") {
      if (inputField.SSN.length !== 9) {
        errorObj.push("SSN");
      }

      if (
        inputField.reEnterSSN === "" ||
        inputField.SSN !== inputField.reEnterSSN
      ) {
        errorObj.push("reEnterSSN");
      }
    }

    if (errorObj.length > 0) {
      setErrors(errorObj);
      //  return;
    }

    if (!errorObj.length) {
      const updatedObj = {
        legalEntity: inputField.legalEntity,
        legalEntityOther: inputField.legalEntityOther,
        ein: inputField.EIN,
        ssn: inputField.SSN,
      };

      dispatch(updateInfo(updatedObj));
      dispatch(
        updateSidebarStatus({
          menuItem: menuNumber,
          isComplete: true,
        })
      );
      navigate(FINANCE_BANK_INFO_URL);
    }
  };

  const navigateBack = () => {
    setshowCancel(true);

    if (!isPageUpdated) {
      navigate(FINANCE_ENTITY_DETAIL_URL);
    }
  };

  return (
    <>
      {showCancel ? (
        <CancelNavigation
          navLink={FINANCE_ENTITY_DETAIL_URL}
          onCancelClick={() => setshowCancel(!showCancel)}
          menuItem={menuNumber}
        />
      ) : (
        <></>
      )}
      <GlobalHeader />
      <div className="d-flex flex-row ">
        <div className="container-fluid ps-0 pe-5">
          <div className="row g-3 g-sm-0 d-flex justify-content-left">
            <div className="col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container grayBackground">
              <Sidebar activeIndex={menuNumber} />
            </div>
            <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight">
              <Breadcrumb innerPage="Tax Information" />
              <PageHeader title={Content.taxInfo.title} isForm={true}>
                {Content.taxInfo.subtitle}
              </PageHeader>
              <div className="text-muted">
                Note: Nonprofit corporations exempt under IRS Code Section
                501(c)(3) should select “Other” below and enter “501(c)(3)” into
                the text box.
              </div>
              <div className="mt-3">
                <form className="row g-3 needs-validation">
                  <div className="row mt-3">
                    <div className="col-md-4">
                      <label className=" form-label mb-2" htmlFor="validation1">
                        {Content.taxInfo.label}
                        <span className="text-danger">*</span>
                      </label>
                      <select
                        name="legalEntity"
                        id="validation1"
                        aria-label="Legal Entity"
                        className={
                          hasError("legalEntity")
                            ? "form-select is-invalid"
                            : "form-select"
                        }
                        value={inputField.legalEntity}
                        onChange={inputsHandler}
                        required
                      >
                        <option value="selectEntity">Select</option>
                        {Content.Options.map((ele) => {
                          return (
                            <option key={ele.code} value={ele.code}>
                              {ele.description}
                            </option>
                          );
                        })}

                        {/* <option value="individual">
                              Individuals/Sole Proprietorship
                            </option>
                            <option value="other">Other</option> */}
                      </select>
                      <div className="invalid-feedback">Select entity type</div>
                    </div>
                  </div>

                  {taxType?.type === "EIN" && (
                    <OptionOther
                      errors={errors}
                      inputsHandler={inputsHandler}
                      inputField={inputField}
                    />
                  )}

                  {taxType?.type === "SSN" && (
                    <OptionIndividual
                      errors={errors}
                      inputsHandler={inputsHandler}
                      inputField={inputField}
                    />
                  )}
                </form>
                <div className="col-12 mb-5">
                  <button
                    type="button"
                    aria-label="Previous Button"
                    className="btn btn-pp-secondary-outline me-3 mt-4"
                    onClick={navigateBack}
                  >
                    Previous
                  </button>

                  <button
                    type="submit"
                    aria-label="Next Button"
                    className="btn btn-pp-secondary mt-4"
                    onClick={saveInfo}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaxInfoPage;
