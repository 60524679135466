import { EntityIcon } from "../../Assets/LeftMenu/Entity.js";
import { RepresentativeIcon } from "../../Assets/LeftMenu/Representative.js";
import { TaxIcon } from "../../Assets/LeftMenu/Tax.js";
import { BankingIcon } from "../../Assets/LeftMenu/Banking.js";
import { W9Icon } from "../../Assets/LeftMenu/W9.js";
import { ReviewIcon } from "../../Assets/LeftMenu/Review.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useState } from "react";
import "./Sidebar.scss";
import CancelNavigation from "../CancelNavigation/CancelNavigation.js";

import SidebarElement from "./SidebarElement.js";

const Sidebar = (props) => {
  const activeIndex = props.activeIndex;
  const navigate = useNavigate();

  const [showPop, setShowPop] = useState(null);
  const [navigateTo, setNavigateTo] = useState(null);

  const pageInfo = useSelector((state) => state.leftMenu.menuItems);
  const dirtyPage = pageInfo.find((x) => x.isComplete === false);

  const manageNavigation = (link) => {
    // If updated page show Popup

    if (dirtyPage) {
      setShowPop(true);
      setNavigateTo(link);
    } else {
      navigate(link);
    }
  };

  return (
    <>
      {showPop ? (
        <CancelNavigation
          navLink={navigateTo}
          onCancelClick={() => setShowPop(!showPop)}
          menuItem={activeIndex}
        ></CancelNavigation>
      ) : (
        <> </>
      )}

      <div className="bd-sidebar lightGray">
        <div
          className="offcanvas-lg offcanvas-start "
          tabIndex={-1}
          id="bdSidebar"
          aria-labelledby="bdSidebarOffcanvasLabel"
        >
          <div className="offcanvas-header border-bottom">
            {/* Removed heading on the top on mobile menu */}
            {/* <h5 aria-hidden="true" className="offcanvas-title" id="bdSidebarOffcanvasLabel">
              Sections
            </h5> */}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              data-bs-target="#bdSidebar"
            ></button>
          </div>

          <div className="offcanvas-body">
            <nav
              className="bd-links w-100"
              id="bd-docs-nav"
              aria-label="Docs navigation"
            >
              {/* Link starts here */}

              <div className="mt-5">
                <SidebarElement
                  icon={EntityIcon}
                  pageInfo={pageInfo}
                  activeIndex={activeIndex}
                  index={0}
                  manageNavigation={manageNavigation}
                  name="Home"
                  navlink="/"
                />
              </div>

              <SidebarElement
                icon={RepresentativeIcon}
                pageInfo={pageInfo}
                activeIndex={activeIndex}
                index={2}
                manageNavigation={manageNavigation}
                name="Representative"
                navlink="/representative"
              />

              <SidebarElement
                icon={EntityIcon}
                pageInfo={pageInfo}
                activeIndex={activeIndex}
                index={3}
                manageNavigation={manageNavigation}
                name="Entity Details"
                navlink="/entitydetails"
              />

              <SidebarElement
                icon={TaxIcon}
                pageInfo={pageInfo}
                activeIndex={activeIndex}
                index={4}
                manageNavigation={manageNavigation}
                name="Tax Information"
                navlink="/tax-info"
              />

              <SidebarElement
                icon={BankingIcon}
                pageInfo={pageInfo}
                activeIndex={activeIndex}
                index={5}
                manageNavigation={manageNavigation}
                name="Banking Information"
                navlink="/bank-info"
              />

              <SidebarElement
                icon={W9Icon}
                pageInfo={pageInfo}
                activeIndex={activeIndex}
                index={6}
                manageNavigation={manageNavigation}
                name="W-9 Form Upload"
                navlink="/W-9-form-upload"
              />

              <SidebarElement
                icon={ReviewIcon}
                pageInfo={pageInfo}
                activeIndex={activeIndex}
                index={7}
                manageNavigation={manageNavigation}
                name="Review and Submit"
                navlink="/review"
              />
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
