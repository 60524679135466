import { useState } from "react";
import { ReactComponent as SHOW_PASSWORD } from "../../Images/GlobalHeader/SHOW_PASSWORD.svg";
import { ReactComponent as HIDE_PASSWORD } from "../../Images/GlobalHeader/HIDE_PASSWORD.svg";
function OptionOther({ errors, inputsHandler, inputField }) {
  const [isPasswordShow, setIsPasswordShow] = useState([false, false]);
  // const [isEINMismatch, setisEINMismatch] = useState(false);

  const toggleIsPasswordShowValue = (item) => {
    const updatedToggle = isPasswordShow.map((ele, i) => {
      return i === item ? !ele : ele;
    });
    setIsPasswordShow(updatedToggle);
  };

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  // const formatEIN = (number) => {
  //   const formattedNumber = number.replace(/[^0-9]+/g, "");
  //   if (formattedNumber.length > 0) {
  //     if (formattedNumber.length < 2) {
  //       return formattedNumber;
  //     }
  //     if (formattedNumber.length > 2) {
  //       return `${formattedNumber.slice(0, 2)}-${formattedNumber.slice(2, 9)}`;
  //     }
  //   }
  //   return formattedNumber;
  // };
  //
  // const validateEIN = (number) => {
  //   if (number.length === 11 && number !== inputField.EIN && !isEINMismatch) {
  //     setisEINMismatch(true);
  //   }
  //   if (number.length < 11 && isEINMismatch) {
  //     setisEINMismatch(false);
  //   }
  //   return formatEIN(number);
  // };

  return (
    <div>
      <div className="mt-3">
        {inputField.legalEntity === "OT" && (
          <div className="row">
            <div className="col-md-4">
              <input
                type="text"
                className={
                  hasError("legalEntityOther")
                    ? "form-control  is-invalid"
                    : "form-control"
                }
                name="legalEntityOther"
                required
                onChange={inputsHandler}
                value={inputField.legalEntityOther}
                maxLength={50}
                autoComplete="-ignore-"
              />
              <div className="invalid-feedback">Enter other entity type</div>
            </div>
            <p htmlFor="otherOption *" className="text-muted pt-2">
              Please enter other option<span className="text-danger">*</span>
            </p>
          </div>
        )}
      </div>
      <div className="mt-3">
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="EIN *" className="form-label mb-2">
              Enter Employer Identification Number (EIN)
              <span className="text-danger">*</span>
            </label>
            <div className="row ">
              <div className="input-group">
                <input
                  type={isPasswordShow[0] ? "text" : "password"}
                  className={
                    hasError("EIN")
                      ? "form-control  is-invalid "
                      : "form-control "
                  }
                  name="EIN"
                  onChange={inputsHandler}
                  value={inputField.EIN}
                  maxLength={11}
                  autoComplete="-ignore-"
                />
                <div
                  onClick={() => toggleIsPasswordShowValue(0)}
                  className="input-group-text"
                  id="btnGroupAddon"
                >
                  {isPasswordShow[0] ? <SHOW_PASSWORD /> : <HIDE_PASSWORD />}
                </div>
                <div className="invalid-feedback ">Enter EIN number</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="row">
          <div className="col-md-4 mb-3">
            <label htmlFor="EIN *" className="form-label mb-2">
              Re-Enter Employer Identification Number (EIN)
              <span className="text-danger">*</span>
            </label>
            <div className="row">
              <div className="input-group">
                <input
                  type={isPasswordShow[1] ? "text" : "password"}
                  className={
                    hasError("reEnterEIN")
                      ? "form-control  is-invalid "
                      : "form-control"
                  }
                  name="reEnterEIN"
                  required
                  onChange={inputsHandler}
                  value={inputField.reEnterEIN}
                  maxLength={11}
                  autoComplete="-ignore-"
                />

                <div
                  onClick={() => toggleIsPasswordShowValue(1)}
                  className="input-group-text"
                  id="btnGroupAddon2"
                >
                  {isPasswordShow[1] ? <SHOW_PASSWORD /> : <HIDE_PASSWORD />}
                </div>
                <div className="invalid-feedback ">
                  {" "}
                  Value does not match with EIN
                </div>
              </div>
            </div>

            <p className="text-muted pt-2">Re-enter for verification</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptionOther;
