import { Link, useNavigate } from "react-router-dom";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { Content } from "./Content";
import ReviewTable from "./ReviewTable";
import "../../Styles/style.scss";
import {
  FINANCE_SUBMIT_URL,
  FINANCE_W9_UPLOAD_URL,
} from "../../Constants/Constants";
import useValidNavigation from "../../Elements/useValidNavigationHook/useValidNavigation";
import { updateSidebarStatus } from "../../Redux/Slices/leftMenu";
import { useDispatch } from "react-redux";

function ReviewPage() {
  const menuNumber = 7;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Custom Hook to check if a user can access this page.
  useValidNavigation(FINANCE_W9_UPLOAD_URL, menuNumber);

  const doNavigate = () => {
    dispatch(
      updateSidebarStatus({
        menuItem: menuNumber,
        isComplete: true,
      })
    );
    navigate(FINANCE_SUBMIT_URL);
  };

  return (
    <div>
      <GlobalHeader />
      <div className="d-flex flex-row">
        <div className="container-fluid ps-0 pe-5">
          <div className="row g-3 g-sm-0 d-flex justify-content-left">
            <div className="col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container grayBackground">
              <Sidebar activeIndex={menuNumber} />
            </div>
            <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight">
              <Breadcrumb innerPage="Review" />
              <h1
                aria-hidden="true"
                className="fs-5 mb-3 page-header text-pp-secondary"
              >
                {Content.ReviewPage.title}
              </h1>

              <p className="fs-6">
                {Content.ReviewPage.subtitleOne}
                <span className="fw-bold">`Previous`</span>
                {Content.ReviewPage.subtitleTwo} For assistance in completing
                this form, please contact our team at:{" "}
                <a
                  aria-label="Cited Email Link"
                  href="mailto:finance@ca-path.com "
                >
                  finance@ca-path.com
                </a>{" "}
                <span> or </span>
                <span className="fw-semibold"> (866) 529-7550</span>.
              </p>
              <ReviewTable />
              <Link to={FINANCE_W9_UPLOAD_URL}>
                <button
                  type="button"
                  className="btn btn-pp-secondary-outline me-4"
                >
                  Previous
                </button>
              </Link>
              <button
                type="button"
                className="btn btn-pp-secondary"
                onClick={doNavigate}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReviewPage;
