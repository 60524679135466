export const Content = {
  EntityDetailsPage: {
    title: "Confirm Organization",
    subtitle: "Please review and verify the details below.",
  },
  Error:
    " The account was not able to be linked to the  organization. Please try again or contact",
  Contact: "cited@ca-path.com",
  Disclaimer: `By selecting this checkbox you are confirming and
  attesting that the information above is accurate and
  is the organization for which you are affiliated as
  the financial delegate.`,
};
