import { Modal } from "../../../node_modules/bootstrap/dist/js/bootstrap.esm.min";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router";
import { updateSidebarStatus } from "../../Redux/Slices/leftMenu";

const CancelNavigation = ({ navLink, onCancelClick, menuItem }) => {
  const modalRef = useRef();
  const navigate = useNavigate();
  const cancelRef = useRef();
  const dispatch = useDispatch();

  const pageInfo = useSelector((state) => state.leftMenu.menuItems);
  // if any page is in dirty state show popup. Todo:Manage it from calling component
  const dirtyPage = pageInfo.find((x) => x.isComplete === false);

  useEffect(() => {
    const myModal = modalRef.current;
    let bsModal = Modal.getInstance(myModal);

    if (!bsModal) {
      // initialize Toast
      bsModal = new Modal(myModal);
    }

    if (dirtyPage) {
      bsModal.show();
    }
  });

  const navigateTo = () => {
    // dummy click to remove the background
    cancelRef.current.click();

    // clean the dirty state
    dispatch(
      updateSidebarStatus({
        menuItem,
        isComplete: null,
      })
    );

    navigate(navLink);
  };

  return (
    <>
      <div className="modal" tabIndex="-1" id="myModal" ref={modalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 aria-hidden="true" className="modal-title">Are you sure to continue?</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                If you continue, any changes made to this page will be lost.
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={onCancelClick}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={navigateTo}
              >
                Continue
              </button>

              {/* hidden button to cancel the modal background */}
              <button
                type="button"
                className="btn btn-outline-secondary"
                data-bs-dismiss="modal"
                ref={cancelRef}
                style={{ display: "none" }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelNavigation;
