import { useState } from "react";
import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { Content } from "./Content";
import { useDispatch, useSelector } from "react-redux";
import { updateInfo } from "../../Redux/Slices/bankingInfo";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { useNavigate } from "react-router";
import CancelNavigation from "../../Components/CancelNavigation/CancelNavigation";

import { updateSidebarStatus } from "../../Redux/Slices/leftMenu";
import {
  CITY_VALIDATION,
  FINANCE_REPRESENTATIVE_URL,
  FINANCE_TAX_INFO_URL,
  ZIP_VALIDATION,
  removeWhiteSpaces,
} from "../../Constants/Constants";
import useValidNavigation from "../../Elements/useValidNavigationHook/useValidNavigation";

const EntityDetails = () => {
  const menuNumber = 3;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Custom Hook to check if a user can access this page.
  useValidNavigation(FINANCE_REPRESENTATIVE_URL, menuNumber);

  const pageInfo = useSelector((state) => state.bankingInfo.formInfo);

  const localobj = {
    LegalBusinessName: pageInfo.legalBizName,
    LegalAddressStreetName: pageInfo.addressLine1,
    LegalAddressUnit: pageInfo.addressLine2,
    city: pageInfo.city,
    State: pageInfo.state,
    ZipCode: pageInfo.zip,
  };

  // set input state
  const [inputField, setInputField] = useState(localobj);
  const [errors, setErrors] = useState([]);
  const [isPageUpdated, setIsPageUpdated] = useState(false);
  const [showCancel, setshowCancel] = useState("");

  const inputsHandler = (e) => {
    if (!isPageUpdated) setIsPageUpdated(true);
    const { name, value } = e.target;
    if (errors.includes(name)) {
      setErrors(errors.filter((ele) => ele !== name));
    }
    switch (name) {
      case "city":
        value.match(CITY_VALIDATION)
          ? setErrors(errors.filter((ele) => ele !== name))
          : setErrors((prev) => [...prev, name]);
        break;
      case "ZipCode":
        if (!value.match(ZIP_VALIDATION)) return;
        break;
    }
    setInputField({ ...inputField, [name]: value });
  };

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  // create your page object
  const saveInfo = () => {
    const errorObj = [];
    for (const [key, value] of Object.entries(inputField)) {
      switch (key) {
        case "city":
          if (!value.match(CITY_VALIDATION)) errorObj.push(key);
          break;
        case "LegalAddressUnit":
          break;
        case "ZipCode":
          if (value.length < 5) {
            errorObj.push(key);
          }
          break;
        case "State":
          if (value === "Please Select") {
            errorObj.push(key);
          }
          break;
        default:
          if (value.length <= 0) errorObj.push(key);
      }
    }

    if (errorObj.length > 0) {
      setErrors(errorObj);
      //  return;
    }

    if (!errorObj.length) {
      const updatedObj = {
        legalBizName: removeWhiteSpaces(inputField.LegalBusinessName),
        addressLine1: removeWhiteSpaces(inputField.LegalAddressStreetName),
        addressLine2: removeWhiteSpaces(inputField.LegalAddressUnit),
        city: removeWhiteSpaces(inputField.city),
        state: inputField.State,
        zip: inputField.ZipCode,
      };
      // dispatch your object to the store
      dispatch(updateInfo(updatedObj));

      dispatch(
        updateSidebarStatus({
          menuItem: menuNumber,
          isComplete: true,
        })
      );
      navigate(FINANCE_TAX_INFO_URL);
    }
  };

  const navigateBack = () => {
    setshowCancel(true);

    if (!isPageUpdated) {
      navigate(FINANCE_REPRESENTATIVE_URL);
    }
  };

  return (
    <>
      {showCancel ? (
        <CancelNavigation
          navLink={FINANCE_REPRESENTATIVE_URL}
          onCancelClick={() => setshowCancel(!showCancel)}
          menuItem={menuNumber}
        />
      ) : (
        <></>
      )}

      <GlobalHeader />
      <div className="d-flex flex-row">
        <div className="container-fluid ps-0 pe-5">
          <div className="row g-3 g-sm-0 d-flex justify-content-left">
            <div className="col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container grayBackground">
              <Sidebar activeIndex={menuNumber} />
            </div>

            <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight">
              <Breadcrumb innerPage="Entity Details" />
              <div className="container-fluid py-md-4">
                <PageHeader
                  title={Content.EntityDetailsPage.title}
                  isForm={true}
                >
                  {Content.EntityDetailsPage.subtitle}
                </PageHeader>

                <div className="mt-3 ">
                  <form className="row g-3" autoComplete="off">
                    <div className="row mt-3">
                      <div className="col-md-4">
                        <label
                          htmlFor="LegalBusinessName"
                          className="form-label"
                        >
                          Legal Business Name
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            hasError("LegalBusinessName")
                              ? "form-control  is-invalid"
                              : "form-control"
                          }
                          name="LegalBusinessName"
                          id="LegalBusinessName"
                          aria-label="Legal Business name"
                          onChange={inputsHandler}
                          value={inputField.LegalBusinessName}
                          required
                          maxLength={50}
                          autoComplete="-ignore-"
                        />
                        <div className="invalid-feedback">
                          Enter business name
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3 ">
                      <div className="col-md-4">
                        <label htmlFor="LegalAddress" className="form-label">
                          Legal Address of the applying organization or entity
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            hasError("LegalAddressStreetName")
                              ? "form-control mb-3  is-invalid"
                              : "form-control mb-3"
                          }
                          name="LegalAddressStreetName"
                          id="LegalAddress"
                          aria-label="Legal Address"
                          onChange={inputsHandler}
                          value={inputField.LegalAddressStreetName}
                          placeholder="Street Name"
                          required
                          maxLength={50}
                          autoComplete="-ignore-"
                        />

                        <input
                          type="text"
                          className={
                            hasError("LegalAddressUnit")
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          name="LegalAddressUnit"
                          onChange={inputsHandler}
                          value={inputField.LegalAddressUnit}
                          placeholder="Unit or Suite Number"
                          required
                          maxLength={50}
                          autoComplete="-ignore-"
                        />
                        <div className="invalid-feedback">
                          Enter street name or unit number
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3 ">
                      <div className="col-md-2">
                        <label htmlFor="legalcity" className="form-label">
                          City<span className="text-danger">*</span>
                        </label>
                        <div className="invalid-feedback">Enter city</div>
                        <input
                          type="text"
                          className={
                            hasError("city")
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          name="city"
                          id="legalcity"
                          aria-label="Legal City"
                          onChange={inputsHandler}
                          value={inputField.city}
                          required
                          maxLength={50}
                          autoComplete="-ignore-"
                        />
                        <div className="invalid-feedback">Enter city</div>
                      </div>

                      <div className="col-md-2">
                        <label htmlFor="legalState" className="form-label">
                          State<span className="text-danger">*</span>
                        </label>

                        <select
                          className={
                            hasError("State")
                              ? "form-select is-invalid"
                              : "form-select"
                          }
                          name="State"
                          aria-label="Select State"
                          placeholder="Select"
                          id="legalState"
                          onChange={inputsHandler}
                          value={inputField.State}
                        >
                          <option key="stateValues" value="Please Select">
                            Please Select
                          </option>

                          {Content.EntityDetailsPage.state.map((ele) => {
                            return (
                              <option key={ele} value={ele}>
                                {ele}
                              </option>
                            );
                          })}
                        </select>

                        <div className="invalid-feedback">Select state</div>
                      </div>
                    </div>

                    <div className="row mt-3 mb-4">
                      <div className="col-md-2">
                        <label htmlFor="ZipCode" className="form-label">
                          Zip Code<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={
                            hasError("ZipCode")
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          name="ZipCode"
                          id="ZipCode"
                          aria-label="Zip Code"
                          onChange={inputsHandler}
                          value={inputField.ZipCode}
                          required
                          maxLength={5}
                          autoComplete="-ignore-"
                        />
                        <div className="invalid-feedback">Enter ZipCode</div>
                      </div>
                    </div>
                  </form>
                  <div className="col-12 mb-5">
                    <button
                      type="button"
                      aria-label="Previous Button"
                      className="btn btn-pp-secondary-outline me-3 mt-4"
                      onClick={navigateBack}
                    >
                      Previous
                    </button>
                    <button
                      type="submit"
                      aria-label="Next Button"
                      className="btn btn-pp-secondary mt-4"
                      onClick={saveInfo}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EntityDetails;
