export const RepresentativeIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8ZM10.6667 4C10.6667 5.47276 9.47276 6.66667 8 6.66667C6.52724 6.66667 5.33333 5.47276 5.33333 4C5.33333 2.52724 6.52724 1.33333 8 1.33333C9.47276 1.33333 10.6667 2.52724 10.6667 4Z"
        fill={props.color}
      />
      <path
        d="M16 14.6667C16 16 14.6667 16 14.6667 16H1.33333C1.33333 16 0 16 0 14.6667C0 13.3333 1.33333 9.33333 8 9.33333C14.6667 9.33333 16 13.3333 16 14.6667ZM14.6666 14.662C14.6647 14.333 14.4616 13.3473 13.5572 12.4428C12.6875 11.5731 11.0521 10.6667 7.99999 10.6667C4.94784 10.6667 3.31247 11.5731 2.4428 12.4428C1.53834 13.3473 1.33523 14.333 1.33333 14.662H14.6666Z"
        fill={props.color}
      />
    </svg>
  );
};
