import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { useState } from "react";
import { Content } from "./Content";
import { useDispatch, useSelector } from "react-redux";
import { updateInfo } from "../../Redux/Slices/bankingInfo";
import Sidebar from "../../Components/Sidebar/Sidebar";
import {
  EMAIL_VALIDATION,
  FINANCE_ENTITY_DETAIL_URL,
  FINANCE_INTRODUCTION_URL,
  FORMATTED_PHONE_VALIDATION,
  NAME_VALIDATION,
  removeWhiteSpaces,
} from "../../Constants/Constants";

import { updateSidebarStatus } from "../../Redux/Slices/leftMenu";
import { useNavigate } from "react-router-dom";
import CancelNavigation from "../../Components/CancelNavigation/CancelNavigation";
import useValidNavigation from "../../Elements/useValidNavigationHook/useValidNavigation";
const Representative = () => {
  const menuNumber = 2;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Custom Hook to check if a user can access this page.
  useValidNavigation(FINANCE_INTRODUCTION_URL, menuNumber);

  const pageInfo = useSelector((state) => state.bankingInfo.formInfo);

  // Bring the info from the state
  const localobj = {
    firstName: pageInfo.firstName,
    lastName: pageInfo.lastName,
    title: pageInfo.title,
    phone: pageInfo.phone,
    email: pageInfo.email,
  };

  // set input state
  const [inputField, setInputField] = useState(localobj);
  const [errors, setErrors] = useState([localobj]);
  const [isPageUpdated, setIsPageUpdated] = useState(false);
  const [showCancel, setshowCancel] = useState("");

  const inputsHandler = (e) => {
    if (!isPageUpdated) setIsPageUpdated(true);
    const { name, value } = e.target;
    // Remove error if started to type
    if (errors.includes(e.target.name)) {
      setErrors(errors.filter((ele) => ele !== e.target.name));
    }
    switch (name) {
      case "firstName":
        value.match(NAME_VALIDATION)
          ? setErrors(errors.filter((ele) => ele !== name))
          : setErrors((prev) => [...prev, name]);
        break;
      case "lastName":
        value.match(NAME_VALIDATION)
          ? setErrors(errors.filter((ele) => ele !== name))
          : setErrors((prev) => [...prev, name]);
        break;
      // case "email":
      //   value.match(EMAIL_VALIDATION)
      //     ? setErrors(errors.filter((ele) => ele !== name))
      //     : setErrors((prev) => [...prev, name]);
      //   break;
      case "phone":
        if (!value.match(FORMATTED_PHONE_VALIDATION)) return;
        break;
    }
    setInputField({
      ...inputField,
      [name]: value,
    });
  };

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const formatPhone = (number) => {
    // Filter non numbers-
    const formattedNumber = number.replace(/[^0-9]+/g, "");

    if (formattedNumber.length > 0) {
      if (formattedNumber.length < 4) {
        return "(" + formattedNumber + "";
      }
      if (formattedNumber.length > 3 && formattedNumber.length < 7) {
        return `(${formattedNumber.slice(0, 3)})-${formattedNumber.slice(3)}`;
      }
      if (formattedNumber.length > 6) {
        return `(${formattedNumber.slice(0, 3)})-${formattedNumber.slice(
          3,
          6
        )}-${formattedNumber.slice(6, 10)}`;
      }
    }
    return formattedNumber;
  };

  // create your page object
  const saveInfo = () => {
    // Validations
    const errorObj = [];
    for (const [key, value] of Object.entries(inputField)) {
      switch (key) {
        case "firstName":
          if (!value.match(NAME_VALIDATION)) errorObj.push(key);
          break;
        case "lastName":
          if (!value.match(NAME_VALIDATION)) errorObj.push(key);
          break;
        case "phone":
          if (value.length < 14) errorObj.push(key);
          break;
        case "email":
          if (!value.match(EMAIL_VALIDATION)) errorObj.push(key);
          break;
        default:
          if (value.length <= 0) errorObj.push(key);
      }
    }

    if (errorObj.length > 0) {
      setErrors(errorObj);
    }

    if (!errorObj.length) {
      const updatedObj = {
        firstName: removeWhiteSpaces(inputField.firstName),
        lastName: removeWhiteSpaces(inputField.lastName),
        title: removeWhiteSpaces(inputField.title),
        phone: removeWhiteSpaces(inputField.phone),
        email: removeWhiteSpaces(inputField.email),
      };
      // dispatch your object to the store
      dispatch(updateInfo(updatedObj));

      dispatch(
        updateSidebarStatus({
          menuItem: menuNumber,
          isComplete: true,
        })
      );

      navigate(FINANCE_ENTITY_DETAIL_URL);
    }
  };
  const navigateBack = () => {
    setshowCancel(true);
    if (!isPageUpdated) {
      navigate(FINANCE_INTRODUCTION_URL);
    }
  };

  return (
    <>
      {showCancel && (
        <CancelNavigation
          navLink={"/"}
          onCancelClick={() => setshowCancel(!showCancel)}
          menuItem={menuNumber}
        />
      )}
      <GlobalHeader />
      <div className="d-flex flex-row">
        <div className="container-fluid ps-0 pe-5">
          <div className="row g-3 g-sm-0 d-flex justify-content-left">
            <div className="col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container grayBackground">
              <Sidebar activeIndex={menuNumber} />
            </div>
            <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight">
              <Breadcrumb innerPage="Representative Information" />
              <PageHeader
                title={Content.RepresentativePage.title}
                isForm={true}
              >
                {Content.RepresentativePage.subtitle}
              </PageHeader>
              <div className="mt-5">
                <form className="row g-3 maxWidth" autoComplete="off">
                  <input type="hidden" value="something" />
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor="firstName" className="form-label">
                        First Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={
                          hasError("firstName")
                            ? "form-control  is-invalid"
                            : "form-control"
                        }
                        name="firstName"
                        id="firstName"
                        aria-label="First Name"
                        onChange={inputsHandler}
                        value={inputField.firstName}
                        required
                        maxLength={50}
                        autoComplete="-ignore-"
                      />
                      <div className="invalid-feedback">Enter first name</div>
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="lastName" className="form-label">
                        Last Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={
                          hasError("lastName")
                            ? "form-control  is-invalid"
                            : "form-control"
                        }
                        name="lastName"
                        id="lastName"
                        aria-label="Last Name"
                        onChange={inputsHandler}
                        value={inputField.lastName}
                        required
                        maxLength={50}
                        autoComplete="-ignore-"
                      />
                      <div className="invalid-feedback">Enter last name</div>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">
                      <label
                        htmlFor="representativeTitle"
                        className="form-label"
                      >
                        Title / Position
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={
                          hasError("title")
                            ? "form-control  is-invalid"
                            : "form-control"
                        }
                        name="title"
                        id="representativeTitle"
                        aria-label="Title"
                        onChange={inputsHandler}
                        value={inputField.title}
                        required
                        maxLength={50}
                        autoComplete="-ignore-"
                      />
                      <div className="invalid-feedback">Enter title</div>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">
                      <label htmlFor="phone" className="form-label">
                        Phone Number<span className="text-danger">*</span>
                      </label>
                      <input
                        type="tel"
                        className={
                          hasError("phone")
                            ? "form-control  is-invalid"
                            : "form-control"
                        }
                        name="phone"
                        id="phone"
                        aria-label="Phone"
                        onChange={inputsHandler}
                        value={formatPhone(inputField.phone)}
                        required
                        maxLength={14}
                        autoComplete="-ignore-"
                      />
                      <div className="invalid-feedback">Enter Phone number</div>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-8">
                      <label
                        htmlFor="representativeEmail"
                        className="form-label"
                      >
                        Email<span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className={
                          hasError("email")
                            ? "form-control  is-invalid"
                            : "form-control"
                        }
                        name="email"
                        id="representativeEmail"
                        aria-label="Email"
                        onChange={inputsHandler}
                        value={inputField.email}
                        maxLength={50}
                        autoComplete="-ignore-"
                      />
                      <div className="invalid-feedback">Enter valid email</div>
                    </div>
                  </div>
                </form>
                <div className="col-12 mb-5">
                  <button
                    type="button"
                    aria-label="Prev Button"
                    className="btn btn-pp-secondary-outline me-3 mt-4"
                    onClick={navigateBack}
                  >
                    Previous
                  </button>

                  <button
                    type="submit"
                    aria-label="Next Button"
                    className="btn btn-pp-secondary mt-4"
                    onClick={saveInfo}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Representative;
