import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { updateSidebarStatus } from "../../Redux/Slices/leftMenu";
import { FINANCE_INTRODUCTION_URL } from "../../Constants/Constants";

/**
 * useValidNavigation
 *  Custom hook to validate against redux if the user should access a page.
 *  If the user should not access the page kick them back to the Introduction page
 *  Otherwise the page has been visited so handle adding that info to redux.
 * @param previousUrl
 * @param currentMenuNumber
 */
function useValidNavigation(previousUrl, currentMenuNumber) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuInfo = useSelector((state) => state.leftMenu.menuItems);
  useEffect(() => {
    const result = menuInfo.find(
      (x) => x.isComplete === true && x.menuItem === currentMenuNumber - 1
    );
    if (result) {
      dispatch(
        updateSidebarStatus({
          menuItem: currentMenuNumber,
          isComplete: false,
        })
      );
    } else {
      navigate(FINANCE_INTRODUCTION_URL);
    }
  }, []);
}
export default useValidNavigation;
