// import { useState } from "react";
import "./SubmitForm.css";

/**
 * Submission form for the Submit and review page
 * @returns {JSX.Element}
 * @constructor
 */
function SubmitForm({ setIsAttested, signature, inputHandler, errors }) {
  return (
    <>
      <form>
        <div className="mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="agreementCheck"
            onChange={(e) => {
              setIsAttested(e.target.checked);
            }}
            required
          />
          <label className="form-check-label" htmlFor="agreementCheck">
            I agree to the <a href="#">attestations</a> outlined above
            <span className="text-danger">*</span>
          </label>
        </div>
        <div className="mb-3">
          <label htmlFor="signature" className="form-label">
            E-Signature (Please type your name)
            <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            id="signature"
            name="signature"
            aria-describedby="signatureHelp"
            value={signature}
            onChange={inputHandler}
            required
            maxLength={50}
            autoComplete="off"
          />
          {errors && (
            <p className="text-danger">Please enter a valid signature.</p>
          )}
        </div>
      </form>
    </>
  );
}
export default SubmitForm;
