export const W9Icon = (props) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40576 1.34182C5.31176 0.560456 6.57359 0 8 0C10.6902 0 12.9233 1.99944 13.1657 4.57898C14.7581 4.80411 16 6.13656 16 7.77273C16 9.56949 14.5023 11 12.6875 11H10C9.72386 11 9.5 10.7761 9.5 10.5C9.5 10.2239 9.72386 10 10 10H12.6875C13.9793 10 15 8.98842 15 7.77273C15 6.55703 13.9793 5.54545 12.6875 5.54545H12.1875V5.04545C12.1875 2.8256 10.3273 1 8 1C6.83758 1 5.80253 1.45773 5.05886 2.09909C4.30231 2.75157 3.90625 3.5383 3.90625 4.15455V4.6026L3.46088 4.65155C2.06371 4.80512 1 5.95266 1 7.31818C1 8.78492 2.23059 10 3.78125 10H6C6.27614 10 6.5 10.2239 6.5 10.5C6.5 10.7761 6.27614 11 6 11H3.78125C1.70754 11 0 9.36599 0 7.31818C0 5.55511 1.26586 4.09512 2.94223 3.725C3.08479 2.8617 3.63985 2.00237 4.40576 1.34182Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64645 4.14645C7.84171 3.95118 8.15829 3.95118 8.35355 4.14645L11.3536 7.14645C11.5488 7.34171 11.5488 7.65829 11.3536 7.85355C11.1583 8.04882 10.8417 8.04882 10.6464 7.85355L8.5 5.70711V14.5C8.5 14.7761 8.27614 15 8 15C7.72386 15 7.5 14.7761 7.5 14.5V5.70711L5.35355 7.85355C5.15829 8.04882 4.84171 8.04882 4.64645 7.85355C4.45118 7.65829 4.45118 7.34171 4.64645 7.14645L7.64645 4.14645Z"
        fill={props.color}
      />
    </svg>
  );
};
