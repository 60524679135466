import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import { ReactComponent as BANK_ICON } from "../../Images/GlobalHeader/BANK_ICON.svg";
import { useNavigate } from "react-router";
import LandingSidebar from "../../Components/Sidebar/LandingSidebar";
import "../../Styles/FormStyles.scss";
import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Content } from "./Content";
import { useDispatch } from "react-redux";
import { updateUserInfo, getUserStatus } from "../../Redux/Slices/userInfo";
import { LoadingState } from "./LoadingState";
import { ErrorState } from "../../Components/ErrorComponent/ErrorState";
import { resetBankingInfo } from "../../Redux/Slices/bankingInfo";
import { resetLeftMenu } from "../../Redux/Slices/leftMenu";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userAttributes, setUserAttributes] = useState("");
  const [cardContent, setCardContent] = useState();
  const [isError, setisError] = useState(false);

  // Get User info from Authenticator
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        setUserAttributes(data.attributes);
        dispatch(
          updateUserInfo({
            firstName: data.attributes.given_name,
            lastName: data.attributes.family_name,
            phone: data.attributes.phone_number,
            email: data.attributes.email,
          })
        );
        // Reset User infomation to a clean state
        dispatch(resetBankingInfo());
        dispatch(resetLeftMenu());

        fetchUserStatus(data.attributes);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const fetchUserStatus = async (userObj) => {
    try {
      const userStatus = await dispatch(
        getUserStatus({ username: userObj?.email })
      ).unwrap();

      const isFinanceRecord = userStatus.find((ele) => {
        return ele.rcrdAppCd === "FINANCE_RCRD";
      });

      if (isFinanceRecord) {
        const cardProps = Object.entries(Content).find((key) => {
          return key[0] === isFinanceRecord.itemRecordStatus.rcrdStatus;
        });

        cardProps
          ? setCardContent(cardProps[1])
          : setCardContent(Content.NoRecord);
      } else {
        setCardContent(Content.NoRecord);
      }
    } catch (err) {
      console.error(err);
      setisError(true);
    }
  };

  const navigateApp = (link) => {
    navigate(link);
  };

  return (
    <div aria-label="Providing Access and Transforming Health">
      <GlobalHeader />
      <div className="d-flex flex-row">
        <div className="container-fluid ps-0 pe-5">
          <div className="row g-3 g-sm-0 d-flex justify-content-left">
            <div className="col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container grayBackground">
              <LandingSidebar activeIndex={1} />
            </div>

            <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight">
              <div className="row g-sm-0 d-flex justify-content-left">
                <div className="col-sm-8 gx-0 p-3 gx-sm-4">
                  <h1
                    aria-hidden="true"
                    className="fs-5 mb-3 text-pp-secondary"
                  >
                    {userAttributes && (
                      <>
                        Welcome,{" "}
                        {userAttributes?.given_name +
                          " " +
                          userAttributes?.family_name}
                      </>
                    )}
                  </h1>
                  <div className="col-5">
                    <p className="fs-6 mb-2">
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      For assistance with your organization's banking
                      information, please contact{" "}
                      <a
                        aria-label="Finance Email Link"
                        href="mailto:finance@ca-path.com"
                      >
                        finance@ca-path.com
                      </a>
                    </p>
                  </div>

                  {isError ? (
                    <ErrorState message={Content.Error} />
                  ) : (
                    <>
                      <div className="col-md-12 col-lg-8 col-xl-5 gx-0 px-3 pt-2 gx-sm-4">
                        {cardContent ? (
                          <div className="card">
                            <div className="px-2 ms-1 pt-3">
                              <p className="mb-3 text-dark">
                                <span className="me-2 pb-1">
                                  <BANK_ICON />
                                </span>
                                {cardContent?.title}
                              </p>
                              <hr className="border-1 mb-2 me-auto"></hr>
                              <div className="card-body p-0">
                                <p>{cardContent?.body}</p>
                              </div>
                              <div>
                                {cardContent?.linkname && (
                                  <button
                                    className="btn btn-pp-landing mt-3 mb-3 "
                                    onClick={() =>
                                      navigateApp(cardContent?.linkURL)
                                    }
                                  >
                                    {cardContent?.linkname}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          // Empty state
                          <LoadingState />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
