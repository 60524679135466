export const Content = {
  WFormUploadPage: {
    title: "W-9 Form Upload",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
    uploadTitle: "Please upload a completed and signed W-9 form below.",
    uploadSubTitle:
      "Please ensure all information provided in the previous section, Entity Details, matches information on your W-9 form.",
    uploadDragTitle: "Drag and drop your W-9 form here",
  },
};
