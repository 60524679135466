const VerifyingInfo = () => {
  return (
    <div>
      <div className="placeholder-glow col-md-12 col-lg-8 col-xl-8 gx-0 px-3 pt-2 gx-sm-4">
        <span className="placeholder col-4"></span>
        <span className="placeholder placeholder-sm col-9"></span>
        <span className="placeholder placeholder-lg col-12 mt-5"></span>
        <p>
          {" "}
          <span className=" placeholder col-8 mt-3 "></span>{" "}
          <span className=" placeholder col-8 "></span>
        </p>
        <span className="placeholder  placeholder-xs col-12"></span>

        <div className="placeholder-glow">
          <button className="btn col-1 placeholder mt-3 mb-3"></button>
          <button className="btn col-1 placeholder ms-3 mt-3 mb-3"></button>
        </div>
      </div>
    </div>
  );
};

export default VerifyingInfo;
