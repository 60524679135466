import "./index.css";
import * as PropTypes from "prop-types";
function Breadcrumb({ innerPage }) {
  return (
    <div className="bg-white mb-4" id="breadBackground">
      <div
        className="container-fluid py-md-2 border-bottom border-2 px-0"
        id="breadBackground"
      >
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a id="breadItem" aria-label="Home Link" href="/">
                Home
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {innerPage}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
}

Breadcrumb.defaultProps = {
  innerPage: "",
};

Breadcrumb.propTypes = {
  innerPage: PropTypes.string,
};

export default Breadcrumb;
