export const Content = {
  FinancialVerificationPage: {
    title: "Verify Bank Account",
    subtitle:
      "The TPA deposited 2 transactions into the below Financial Institution with an entry of “TPAAcctVer”. As the financial delegate for the corresponding organization, please enter the amount for each transaction in either input field to verify the financial institution on file.",
  },
  Error:
    " We were unable to verify the amounts provided. Please enter the exact amounts deposited into your account or contact ",
  Contact: "finance@ca-path.com",
  NoInfo:
    " There was an error and the page was not able to be loaded. Please try again later.",
};
