import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import { Link } from "react-router-dom";
import { Content } from "./Content";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import SubmitForm from "../../Sections/SubmitPage/SubmitForm/SubmitForm";
import SubmitNavButton from "../../Sections/SubmitPage/SubmitNavButtons/SubmitNavButtons";
import "./SubmitPage.css";
import SubmitScrollArea from "../../Sections/SubmitPage/SubmitScrollArea/SubmitScrollArea";
import { useState } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { NAME_VALIDATION, FINANCE_REVIEW_URL } from "../../Constants/Constants";
import useValidNavigation from "../../Elements/useValidNavigationHook/useValidNavigation";
import { ErrorState } from "../../Components/ErrorComponent/ErrorState";

function SubmitPage() {
  const [isAttested, setIsAttested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState();
  const [errors, setErrors] = useState(false);
  const [inputField, setInputField] = useState({
    signature: "",
    isAttested: false,
  });
  const menuNumber = 8;

  // Custom Hook to check if a user can access this page.
  useValidNavigation(FINANCE_REVIEW_URL, menuNumber);

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (value.length > 64) return;
    setErrors(!value.match(NAME_VALIDATION));
    setInputField({ ...inputField, [name]: value });
  };

  const getErrorText = () => {
    return (
      <span>
        We were unable to submit your Bank Information. For assistance in
        completing this form, please contact our team at:{" "}

        <a href="mailto:finance@ca-path.com" target="_blank" rel="noreferrer">
          finance@ca-path.com
        </a>{" "}
        or call us at{" "}
        <a aria-label="Telephone Link" href="tel:(866) 529-7550">

          (866) 529-7550
        </a>{" "}
        .
      </span>
    );
  };

  return (
    <>
      <GlobalHeader />
      <div className="d-flex flex-row">
        <div className="container-fluid ps-0 pe-5">
          <div className="row g-3 g-sm-0 d-flex justify-content-left">
            <div
              className={
                response
                  ? "col-md-12 col-lg-2 gx-0 gx-sm-4 "
                  : "col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container grayBackground"
              }
            >
              {!response && !isLoading && <Sidebar activeIndex={8} />}
            </div>
            <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight">
              {/* Content that is shown when the page first renders */}
              {!isLoading && !response && (
                <>
                  <Breadcrumb innerPage="Review and Submit" />
                  <PageHeader title={Content.SubmitPage.title} isForm={true}>
                    {Content.SubmitPage.subtitle}
                  </PageHeader>
                  <SubmitScrollArea />
                  <SubmitForm
                    setIsAttested={setIsAttested}
                    signature={inputField.signature}
                    inputHandler={inputsHandler}
                    errors={errors}
                  />
                  <SubmitNavButton
                    isAttested={isAttested}
                    setIsLoading={setIsLoading}
                    setResponse={setResponse}
                    signature={inputField.signature}
                    errors={errors}
                  />
                </>
              )}

              {/* Content that is rendered on submission of the form */}
              {isLoading && (
                <div className="container-fluid placeholder-glow">
                  <div className="container-fluid placeholder">
                    <span className="placeholder"></span>
                  </div>
                  <div className="placeholder mt-3 ps-4">
                    <span className="placeholder"></span>
                  </div>
                  <div className="col-8 mt-3 placeholder">
                    <span className="col-8 placeholder"></span>
                  </div>
                </div>
              )}
              {/* Content that is rendered on response from the API */}
              {!isLoading && response && (
                <div>
                  <div className="container-fluid ps-4 mt-5">
                    {/* Success Condition */}
                    {response.body && (
                      <PageHeader
                        title={Content.sucessMessage + response.body}
                        isForm={false}
                      >
                        <div className="pt-4">
                          <ul className="ps-3">
                            <li>
                              Within the next 5 business days, the TPA will make
                              two small deposits (less than $0.50 each) in your
                              account. The transaction detail on each deposit
                              will show as <strong>TPAAcctVer.</strong>
                            </li>
                            <li>
                              Access your bank account and view your
                              transactions. Identify the two transactions from{" "}
                              <strong>TPAAcctVer</strong> and make note of the{" "}
                              <strong>
                                <ins>exact amounts</ins>
                              </strong>{" "}
                              of the transaction.
                            </li>
                            <li>
                              Visit{" "}
                              <a
                                href="https://finance.ca-path.com"
                                target="_blank"
                                rel="noreferrer"
                              >
                                https://finance.ca-path.com
                              </a>{" "}
                              and enter the exact two amounts that were
                              deposited into your account from{" "}
                              <strong>TPAAcctVer.</strong>
                            </li>
                          </ul>
                          <p>
                            If you have any questions about the financial flow
                            process, please contact{" "}
                            <a href="mailto:finance@ca-path.com.">
                              finance@ca-path.com.
                            </a>
                          </p>
                        </div>
                      </PageHeader>
                    )}
                    {/* Failure Condition */}

                    {response.status && (
                      <>
                        <ErrorState message={getErrorText()} />

                        <p className="mt-5">
                          You may now close the browser or return to your
                          dashboard
                        </p>
                      </>
                    )}
                  </div>
                  <div className="ps-4 pt-4">
                    <Link to="/">
                      <button className="btn btn-pp-secondary">
                        Return to Dashboard
                      </button>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubmitPage;
