import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuItems: [],
  validIndex: "",
};

const leftMenuSlice = createSlice({
  name: "leftMenu",
  initialState,
  reducers: {
    updateSidebarStatus: (state, action) => {
      // add object to state if it's not found. if found replace it
      state.menuItems = [
        ...state.menuItems.filter(
          (obj) => obj.menuItem !== action.payload.menuItem
        ),
        { ...action.payload },
      ];
    },
    resetLeftMenu: () => initialState,
  },
  extraReducers: (builder) => {},
});

export const { updateSidebarStatus, resetLeftMenu } = leftMenuSlice.actions;
export default leftMenuSlice.reducer;
