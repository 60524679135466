import { WarningIcon } from "../../Images/FindYourOrg/warning";

export const ErrorState = (props) => {
  return (
    <div
      className="mt-3 mb-3 pt-3 pb-3 d-flex "
      style={{
        minHeight: "1rem",
        backgroundColor: "#EDC4C8",
      }}
    >
      <span className="my-auto text-center ms-3 ">
        <WarningIcon color="#7A282B" />
      </span>
      <span className=" ms-3">
        <span>{props.message} </span>
        {props.contact && (
          <>
            <span>
              <a aria-label="Contact Link" href={props.contact}>
                {props.contact}
              </a>
            </span>
          </>
        )}
      </span>
    </div>
  );
};
