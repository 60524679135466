import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import LandingSidebar from "../../Components/Sidebar/LandingSidebar";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../node_modules/bootstrap/dist/js/bootstrap.esm.min";
import {
  getFinanceInfo,
  verifyFinanceInfo,
} from "../../Redux/Slices/financialTransaction";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Content } from "./Content";
import { updateSidebarStatus } from "../../Redux/Slices/leftMenu";
import { LoadingState } from "./LoadingState";
import { ErrorState } from "../../Components/ErrorComponent/ErrorState";
import BlankState from "./BlankState";

function FinancialVerificationPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalRef = useRef();
  const cancelRef = useRef();
  const { userProfile, userStatus, itemRecordStatus } = useSelector(
    (state) => state.userInfo
  );
  const { getFinanceInfoStatus, verifyFinanceInfoStatus, financialInfo } =
    useSelector((state) => state.financeInfo);

  // Bring the info from the state
  const localobj = {
    transactionOne: "",
    transactionTwo: "",
    accountName: "",
    routingNum: "",
    accountNum: "",
  };

  const [inputField, setInputField] = useState(localobj);
  const [errors, setErrors] = useState([]);
  const [isPageUpdated, setIsPageUpdated] = useState("");
  const [isUnauthorized, setisUnauthorized] = useState(false);
  const [invalidCounter, setInvalidCounter] = useState(0);
  const [isAPIError, setisAPIError] = useState(false);

  // Control the authorization of the page if accessed directly from URL
  useEffect(() => {
    if (userStatus !== "Pending") setisUnauthorized(true);
  }, []);

  // Get the bank account details
  useEffect(() => {
    getUserAccountInfo(userProfile.email, itemRecordStatus?.rcrdIdentif);
  }, []);

  let bsModal;
  useEffect(() => {
    const myModal = modalRef.current;
    bsModal = Modal.getInstance(myModal);

    if (!bsModal) {
      // initialize Toast
      bsModal = new Modal(myModal);
    }
  });

  const getUserAccountInfo = async (userEmail, rcrdIdentif) => {
    try {
      const userFinanceInfo = await dispatch(
        getFinanceInfo({ userEmail, rcrdIdentif })
      ).unwrap();

      // Null object returned from the api
      if (Object.keys(userFinanceInfo).length === 0) {
        setisAPIError(true);
      }
    } catch (err) {
      setisAPIError(true);
      console.error(err);
    }
  };

  const verifyUserInfo = async () => {
    let isError = false;
    let responseStatus;
    const pennyInfo = {
      appIdentifier: financialInfo.appIdentifier,
      bankInfoIdentifier: financialInfo.bankInfoIdentifier,
      transaction1: inputField.transactionOne.replace("$", ""),
      transaction2: inputField.transactionTwo.replace("$", ""),
    };
    try {
      responseStatus = await dispatch(verifyFinanceInfo(pennyInfo));
    } catch (err) {
      isError = true;
    }

    if (responseStatus?.error || isError) {
      setInvalidCounter(invalidCounter + 1);
      setInputField({
        ...inputField,
        ...{ transactionOne: "", transactionTwo: "" },
      });
    } else {
      bsModal.show();
    }
  };

  const inputsHandler = (e) => {
    if (!isPageUpdated) {
      setIsPageUpdated(true);
      dispatch(
        updateSidebarStatus({
          menuItem: 3,
          isComplete: false,
        })
      );
    }

    setInputField({ ...inputField, [e.target.name]: e.target.value });
    // Remove error if started to type
    if (errors.includes(e.target.name)) {
      setErrors(errors.filter((ele) => ele !== e.target.name));
    }
  };

  const formatAmounts = (number) => {
    // Filter non numbers-
    const formattedNumber = number.replace(/[^0-9\\.]+/g, "");
    if (formattedNumber.length > 0) {
      return `$${formattedNumber}`;
    }
    return formattedNumber;
  };

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const saveInfo = () => {
    // Validations
    const errorOjb = [];

    if (inputField.transactionOne === "") {
      errorOjb.push("transactionOne");
    }

    if (inputField.transactionTwo === "") {
      errorOjb.push("transactionTwo");
    }

    if (errorOjb.length > 0) {
      setErrors(errorOjb);
      //  return;
    }

    if (!errorOjb.length && invalidCounter < 3) {
      verifyUserInfo();

      dispatch(
        updateSidebarStatus({
          menuItem: 2,
          isComplete: true,
        })
      );

      // navigate("/");
    }
  };

  const navigateBack = () => {
    navigate("/");
  };
  const getErrorText = () => {
    return (
      <span>
        We were unable to verify the amounts provided. Please enter the exact
        amounts deposited into your account or contact{" "}
        <a href="mailto:finance@ca-path.com" target="_blank" rel="noreferrer">
          finance@ca-path.com
        </a>
        .
      </span>
    );
  };

  return (
    <>
      {/* Todo:Modal Code to a modal component  */}
      <div className="modal" tabIndex="-1" id="myModal" ref={modalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 aria-hidden="true" className="modal-title">
                Success
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>Your bank account has been successfully verified.</p>
              <p>
                Please note, the <span className="fw-bold">penny deposits</span>{" "}
                will be reversed in the next 10 business days. If you have any
                questions please contact us at{" "}
                <a
                  aria-label="Finance Email Link"
                  href="mailto:finance@ca-path.com"
                >
                  finance@ca-path.com
                </a>
                .
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn  btn-pp-secondary"
                data-bs-dismiss="modal"
                onClick={navigateBack}
              >
                OK
              </button>

              {/* hidden button to cancel the modal background */}
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                ref={cancelRef}
                style={{ display: "none" }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <GlobalHeader />
      <div className="d-flex flex-row">
        <div className="container-fluid ps-0 pe-5">
          <div className="row g-3 g-sm-0 d-flex justify-content-left">
            <div className="col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container grayBackground">
              <LandingSidebar activeIndex={2} />
            </div>
            <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight">
              <Breadcrumb innerPage="Verify Bank Account" />
              {isUnauthorized ? (
                <div> You are not authorized on this page</div>
              ) : (
                <>
                  {getFinanceInfoStatus === "loading" ? (
                    <>
                      <BlankState />
                    </>
                  ) : (
                    <>
                      {isAPIError ? (
                        <ErrorState message={Content.NoInfo} />
                      ) : (
                        <>
                          <PageHeader
                            title={Content.FinancialVerificationPage.title}
                            isForm={true}
                          >
                            {Content.FinancialVerificationPage.subtitle}
                          </PageHeader>
                          <p className="mt-3">
                            Note: Three attempts are allowed only to verify the
                            financial institution: Otherwise, the process
                            restarts.
                          </p>
                          <p
                            className="text-pp-secondary fw-semibold"
                            style={{ fontSize: "18px" }}
                          >
                            Financial Institution Summary:
                          </p>

                          {invalidCounter > 0 && (
                            <ErrorState message={getErrorText()} />
                          )}

                          <div className="col-md-8">
                            <table className="table table-borderless">
                              <thead>
                                <tr>
                                  <th scope="col-2"> Account Holder Name</th>
                                  <th scope="col-2">Routing Number</th>
                                  <th scope="col-2"> Account Number</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  {/* <td>{financialInfo.bankAcctHldrName}</td>
                                  <td>{financialInfo.bankRoutingNum}</td>
                                  <td>{financialInfo.bankNum}</td> */}

                                  <td className="">
                                    {financialInfo.bankAcctHldrName}
                                  </td>
                                  <td className="ps-5">
                                    {" "}
                                    ******{financialInfo.bankRoutingNum}
                                  </td>
                                  <td className="ps-5">
                                    ******{financialInfo.bankNum}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            {/* <div className="row ">
                              <div className="col">
                                Account Holder Name{" "}
                                <span className="text-danger">*</span>
                              </div>

                              <div className="col">
                                Routing Number{" "}
                                <span className="text-danger">*</span>
                              </div>
                              <div className="col">
                                Account Number{" "}
                                <span className="text-danger">*</span>
                              </div>
                            </div>
                            <div className="row mt-3 ">
                              <div className="col">
                                {financialInfo.bankAcctHldrName}
                              </div>

                              <div className="col ms-3">
                                ******{financialInfo.bankRoutingNum}
                              </div>
                              <div className="col ms-3">
                                <div className="col">
                                  ******{financialInfo.bankNum}
                                </div>
                              </div>
                            </div> */}
                          </div>

                          <div className="mt-5">
                            <form className="row g-3" autoComplete="off">
                              <div className="col-md-2">
                                <div>
                                  <label
                                    htmlFor="transactionOne"
                                    className="form-label"
                                  >
                                    Transaction #1
                                    <span className="text-danger">*</span>
                                  </label>

                                  <input
                                    type="text"
                                    className={
                                      hasError("transactionOne")
                                        ? "form-control text-end  is-invalid"
                                        : "form-control text-end"
                                    }
                                    name="transactionOne"
                                    id="transactionOne"
                                    aria-label="Transaction One"
                                    onChange={inputsHandler}
                                    value={formatAmounts(
                                      inputField.transactionOne
                                    )}
                                    required
                                    maxLength={5}
                                    autoComplete="off"
                                    placeholder="$0.00"
                                  />
                                  <div className="invalid-feedback">
                                    Enter a value
                                  </div>
                                </div>
                                <div className=" mt-2">
                                  <label
                                    htmlFor="transactionTwo"
                                    className="form-label"
                                  >
                                    Transaction #2
                                    <span className="text-danger">*</span>
                                  </label>

                                  <input
                                    type="text"
                                    className={
                                      hasError("transactionTwo")
                                        ? "form-control  text-end is-invalid"
                                        : "form-control text-end"
                                    }
                                    name="transactionTwo"
                                    id="transactionTwo"
                                    aria-label="Transaction Two"
                                    onChange={inputsHandler}
                                    value={formatAmounts(
                                      inputField.transactionTwo
                                    )}
                                    required
                                    maxLength={5}
                                    autoComplete="off"
                                    placeholder="$0.00"
                                  />

                                  <div className="invalid-feedback">
                                    Enter a value
                                  </div>
                                </div>
                              </div>
                            </form>
                            <div className="col-12 mb-5">
                              <button
                                type="button"
                                aria-label="Cancel Button"
                                className="btn btn-pp-secondary-outline me-3 mt-4"
                                onClick={navigateBack}
                              >
                                Cancel
                              </button>

                              {verifyFinanceInfoStatus === "loading" ? (
                                <LoadingState />
                              ) : (
                                <button
                                  type="submit"
                                  aria-label="Verify Button"
                                  className={
                                    invalidCounter > 2
                                      ? "btn btn-pp-secondary mt-4 invisible"
                                      : "btn btn-pp-secondary mt-4"
                                  }
                                  onClick={saveInfo}
                                >
                                  Verify
                                </button>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FinancialVerificationPage;
