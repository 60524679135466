import { configureStore } from "@reduxjs/toolkit";
import bankingSlice from "./Slices/bankingInfo";
import leftMenuSlice from "./Slices/leftMenu";
import userInfoSlice from "./Slices/userInfo";
import financeInfoSlice from "./Slices/financialTransaction";

const store = configureStore({
  reducer: {
    // Add Slices:
    bankingInfo: bankingSlice,
    leftMenu: leftMenuSlice,
    userInfo: userInfoSlice,
    financeInfo: financeInfoSlice,
  },
});

export default store;
