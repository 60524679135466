/*eslint-disable */

import { useDropzone } from "react-dropzone";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { ReactComponent as Trash } from "../../Assets/W9-Form/Trash.svg";
import { ReactComponent as FileIcon } from "../../Assets/W9-Form/FileIcon.svg";
import { Modal } from "../../../node_modules/bootstrap/dist/js/bootstrap.esm.min";
import { ReactComponent as ALERT_ICON } from "../../Images/GlobalHeader/ALERT_ICON.svg";
import { FILE_NAME_VALIDATION } from "../../Constants/Constants";

function MyDropzone({
  setIsPageUpdated,
  setW9URL,
  setw9fileName,
  w9fileName,
  W9URL,
}) {
  const [W9File, setW9File] = useState([]);
  const [showUploadError, setShowUploadError] = useState(false);
  const modalRef = useRef();
  const cancelRef = useRef();
  const [bsModal, setBsModal] = useState({});

  useEffect(() => {
    const myModal = modalRef.current;
    let tempBsModal;
    tempBsModal = Modal.getInstance(myModal);

    if (!tempBsModal) {
      tempBsModal = new Modal(myModal);
    }
    setBsModal(tempBsModal);
  }, []);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (W9File.length > 0) {
        // show error message if a file has already been uploaded
        setShowUploadError(true);
      }
      setW9File([...W9File, ...acceptedFiles]);
      setIsPageUpdated(true);
      const localFileURL = window.URL.createObjectURL(acceptedFiles[0]);
      setW9URL(localFileURL);
      setw9fileName(acceptedFiles[0].name);
    },
    [W9File]
  );
  function nameValidator(file) {
    if (!FILE_NAME_VALIDATION.test(file.name)) {
      return {
        code: "invalid-file-name",
        message:
          "The uploaded file name contains special characters. Please enter a different file name with no special characters.\n" +
          "The following characters are allowed: a-z or A-Z, 0-9, !, - , ' (single quote), (, ) and spaces",
      };
    }
    return null;
  }
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    noClick: true,
    maxFiles: 1,
    maxSize: 10485760, //10 mb file
    validator: nameValidator,
    accept: {
      "image/*": [" .JPEG", " .JPG", " .PNG", " .PDF"],
    },
    onDrop,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <div
        key={file.path}
        id="fileID"
        className="alert alert-danger center-v m-0"
      >
        {/* {file.path} - {file.size} bytes */}
        <div className="d-flex alert-dismissible fade show m-0">
          <div className="pt-0 pe-2" style={{ color: "#7A282B" }}>
            <ALERT_ICON />
          </div>
          <div className="ps-2">
            {errors.map((e) => {
              if (e.code === "file-invalid-type") {
                return (
                  <div className="m-0" key={e.code}>
                    <p className="m-0">
                      The file was not uploaded. The file must be JPEG, JPG, PNG
                      or PDF.
                    </p>
                  </div>
                );
              }
              if (e.code === "file-too-large") {
                return (
                  <div className="m-0" key={e.code}>
                    <p className="m-0">
                      The file was not uploaded. The file must be less than 10
                      MB’s.
                    </p>
                  </div>
                );
              }
              if (e.code === "invalid-file-name") {
                return (
                  <div className="m-0" key={e.code}>
                    <p className="m-0">{e.message}</p>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    );
  });

  const removeFile = () => {
    bsModal.show();
  };

  const onDeleteClick = () => {
    setW9File([]);
    setw9fileName(null);
    window.URL.revokeObjectURL(W9URL);
    setW9URL(null);
    bsModal.hide();
    setShowUploadError(false);
  };

  const fileCount = W9File.length;

  return (
    <>
      <>
        <div className="modal" tabIndex="-1" id="myModal" ref={modalRef}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 aria-hidden="true" className="modal-title">
                  Remove File
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to remove this file?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={onDeleteClick}
                >
                  Continue
                </button>

                {/* hidden button to cancel the modal background */}
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                  ref={cancelRef}
                  style={{ display: "none" }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </>

      <section>
        <div
          {...getRootProps({
            className: fileCount === 0 ? "dropzone " : "dropzone disabled",
          })}
        >
          <p>Drag and drop your W-9 form here</p>
          <p className="mt-0">or</p>
          <label
            className={
              fileCount === 0
                ? "btn btn-pp-secondary"
                : "btn btn-pp-secondary disabled"
            }
          >
            Choose File
          </label>
          <input
            {...getInputProps()}
            className={
              fileCount === 0
                ? "btn btn-pp-secondary mt-4 btn-upload-button "
                : "btn btn-pp-secondary mt-4 disabled"
            }
          />
        </div>

        <br />
        <div id="1" style={{ backgroundColor: "#F3D8DB" }}>
          {fileRejectionItems}
          {showUploadError && (
            <div className="m-0 mb-2">
              <p className="m-0">
                Please remove the existing file before uploading a new one.
              </p>
            </div>
          )}
        </div>
        {w9fileName && (
          <aside>
            <div>
              <FileIcon /> {w9fileName}{" "}
              <Trash onClick={removeFile}>Delete</Trash>
            </div>
            {W9File[0] && (
              <div>
                {Math.round((W9File[0]?.size / 1024 / 1024) * 100) / 100}MB
              </div>
            )}
          </aside>
        )}
      </section>
    </>
  );
}
export default MyDropzone;
