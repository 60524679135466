import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  USER_APP_STATUS_API,
  FINANCE_FIND_ORGANIZATION,
} from "../../Constants/Constants";

const initialState = {
  status: "idle",
  userProfile: {
    firstName: "",
    lastName: "",
    username: "",
    phone: "",
    email: "",
  },
  orgProfile: {
    orgName: "",
    addrLine1: "",
    addrLine2: "",
    city: "",
    state: "",
    zip: "",
    zipPlus4: "",
    appIdentifier: "",
  },
  // transaction: { transactionOne: "", transactionTwo: "" },
  userStatus: "",
  apiError: null,
  itemRecordStatus: "",
};

export const getUserStatus = createAsyncThunk(
  "users/getUserStatus",

  async (userInfo) => {
    return await axios
      .get(USER_APP_STATUS_API + userInfo.username)
      .then((res) => {
        return res.data;
      });
  }
);

export const getUserOrgInfo = createAsyncThunk(
  "users/getUserOrgInfo",

  async (formInfo, { rejectWithValue }) => {
    try {
      const response = await axios.post(FINANCE_FIND_ORGANIZATION, {
        ...formInfo,
      });
      return response.data;
    } catch (err) {
      // Return empty value for rejection
      return rejectWithValue(err.response.data.message);
    }
  }
);

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    updateUserInfo: (state, action) => {
      state.userProfile = { ...state.userProfile, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserStatus.fulfilled, (state, action) => {
        const userStatusobj = structuredClone(action.payload);
        const isFinanceRecord = userStatusobj.find((ele) => {
          return ele.rcrdAppCd === "FINANCE_RCRD";
        });

        let userStatusVal;
        if (isFinanceRecord) {
          userStatusVal = isFinanceRecord.itemRecordStatus.rcrdStatus;
        } else {
          userStatusVal = "NoRecord";
        }

        state.userStatus = userStatusVal;
        state.status = "idle";
        state.user = { ...state.user, ...action.payload };

        const financeRecord = action.payload.filter((data) => {
          return (
            data.rcrdAppCd === "FINANCE_RCRD" &&
            data.itemRecordStatus.rcrdIdentif.length > 0 &&
            data.itemRecordStatus.rcrdStatus === "Pending"
          );
        });
        if (financeRecord?.length > 0) {
          state.itemRecordStatus = financeRecord[0].itemRecordStatus;
        } else {
          state.itemRecordStatus = {};
        }
      })
      .addCase(getUserOrgInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserOrgInfo.fulfilled, (state, action) => {
        state.orgProfile = { ...state.orgProfile, ...action.payload };
        state.status = "idle";
      })
      .addCase(getUserOrgInfo.rejected, (state) => {
        state.apiError = true;
        state.status = "idle";
      });
  },
});

export const { updateUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;
