export const Content = {
  NoRecord: {
    title: "Link Organization",
    body: "Find and link your organization to this account.",
    linkname: "Find Organization",
    linkURL: "/FindYourOrg",
  },
  AddBank: {
    title: "Add Bank Account",
    body: "Add your organizations bank account information.",
    linkname: "Add",
    linkURL: "/Introduction",
  },
  Submitted: {
    title: "Financial Information Verification Submitted",
    body: "Your information is being reviewed and processed. Please return in 3-5 business days to verify your banking institution.",
    linkname: "",
    linkURL: "",
  },
  Pending: {
    title: "Verify Bank Account",
    body: "The bank account must be verified before funds can be received.",
    linkname: "Verify",
    linkURL: "/FinancialVerification",
  },

  Verified: {
    title: "Bank Account Verified",
    body: "Your bank account is verified",
    linkname: "",
    linkURL: "",
  },
  Error:
    "There was an error and the page was not able to be loaded. Please try again later.",
};
