import { useState } from "react";
import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";

import { Content } from "./Content";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import { updateInfo } from "../../Redux/Slices/bankingInfo";
import "../../Styles/style.scss";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";

import { updateSidebarStatus } from "../../Redux/Slices/leftMenu";
import CancelNavigation from "../../Components/CancelNavigation/CancelNavigation";
import { CheckPopup } from "../../Components/CheckPopup/CheckPopup";
import {
  ACCOUNT_VALIDATION,
  FINANCE_TAX_INFO_URL,
  FINANCE_W9_UPLOAD_URL,
  SNN_EIN_VALIDATION,
  removeWhiteSpaces,
} from "../../Constants/Constants";
import useValidNavigation from "../../Elements/useValidNavigationHook/useValidNavigation";

const BankingInfoPage = () => {
  const menuNumber = 5;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Custom Hook to check if a user can access this page.
  useValidNavigation(FINANCE_TAX_INFO_URL, menuNumber);
  const pageInfo = useSelector((state) => state.bankingInfo.formInfo);

  // Bring the info from the state
  const localobj = {
    bankName: pageInfo.bankName,
    bankHolderName: pageInfo.bankHolderName,
    bankAcctType: pageInfo.bankAcctType,
    bankAcctNum: pageInfo.bankAcctNum,
    reEnterBankAccountNum: pageInfo.bankAcctNum,
    bankRoutingNum: pageInfo.bankRoutingNum,
    reEnterBankRoutingNum: pageInfo.bankRoutingNum,
  };

  const [inputField, setInputField] = useState(localobj);
  const [errors, setErrors] = useState([]);
  const [isPageUpdated, setIsPageUpdated] = useState(false);
  const [showCancel, setshowCancel] = useState("");

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (!isPageUpdated) {
      setIsPageUpdated(true);
      dispatch(
        updateSidebarStatus({
          menuItem: menuNumber,
          isComplete: false,
        })
      );
    }
    if (errors.includes(e.target.name)) {
      setErrors(errors.filter((ele) => ele !== e.target.name));
    }
    switch (name) {
      case "bankAcctNum":
        if (!value.match(ACCOUNT_VALIDATION)) return;
        break;
      case "reEnterBankAccountNum":
        if (!value.match(ACCOUNT_VALIDATION)) return;
        if (value.length > 4) {
          inputField.bankAcctNum === value
            ? setErrors(errors.filter((ele) => ele !== name))
            : setErrors((prev) => [...prev, name]);
        }
        break;
      case "bankRoutingNum":
        if (!value.match(SNN_EIN_VALIDATION)) return;
        break;
      case "reEnterBankRoutingNum":
        if (!value.match(SNN_EIN_VALIDATION)) return;
        if (value.length === 9) {
          inputField.bankRoutingNum === value
            ? setErrors(errors.filter((ele) => ele !== name))
            : setErrors((prev) => [...prev, name]);
        }
        break;
    }

    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const saveInfo = () => {
    const errorObj = [];
    for (const [key, value] of Object.entries(inputField)) {
      switch (key) {
        case "bankRoutingNum":
          if (value.length < 9) errorObj.push(key);
          break;
        case "bankAcctNum":
          if (value.length < 5) errorObj.push(key);
          break;
        case "reEnterBankAccountNum":
          if (inputField.bankAcctNum !== value) errorObj.push(key);
          break;
        case "reEnterBankRoutingNum":
          if (inputField.bankRoutingNum !== value) errorObj.push(key);
          break;
        default:
          if (value.length <= 0) errorObj.push(key);
      }
    }

    if (errorObj.length > 0) {
      setErrors(errorObj);
      //  return;
    }

    if (!errorObj.length) {
      const updatedObj = {
        bankName: removeWhiteSpaces(inputField.bankName),
        bankHolderName: removeWhiteSpaces(inputField.bankHolderName),
        bankAcctType: inputField.bankAcctType,
        bankRoutingNum: inputField.bankRoutingNum,
        bankAcctNum: inputField.bankAcctNum,
      };
      dispatch(updateInfo(updatedObj));
      dispatch(
        updateSidebarStatus({
          menuItem: menuNumber,
          isComplete: true,
        })
      );
      navigate(FINANCE_W9_UPLOAD_URL);
    }
  };

  const navigateBack = () => {
    setshowCancel(true);

    if (!isPageUpdated) {
      navigate(FINANCE_TAX_INFO_URL);
    }
  };
  return (
    <>
      {showCancel ? (
        <CancelNavigation
          navLink={FINANCE_TAX_INFO_URL}
          onCancelClick={() => setshowCancel(!showCancel)}
          menuItem={menuNumber}
        />
      ) : (
        <></>
      )}
      <GlobalHeader />

      <div className="d-flex flex-row">
        <div className="container-fluid ps-0 pe-5">
          <div className="row g-3 g-sm-0 d-flex justify-content-left">
            <div className="col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container grayBackground">
              <Sidebar activeIndex={menuNumber} />
            </div>
            <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight">
              <Breadcrumb innerPage="Banking Information" />
              <PageHeader title={Content.BankInfoPage.title} isForm={true}>
                {Content.BankInfoPage.subtitle}
              </PageHeader>
              <div className="mt-5 w-75">
                <form className="row g-3 maxWidth">
                  <div className="row">
                    <div className="col-md-8">
                      <label htmlFor="bankInstitute" className="form-label">
                        Name of Banking Institution
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={
                          hasError("bankName")
                            ? "form-control  is-invalid"
                            : "form-control"
                        }
                        name="bankName"
                        id="bankInstitute"
                        aria-label="Bank Institute"
                        onChange={inputsHandler}
                        value={inputField.bankName}
                        required
                        maxLength={50}
                      />
                      <div className="invalid-feedback">
                        Enter bank information
                      </div>
                    </div>
                    <div className="col-md-8 mt-4 ">
                      <label htmlFor="bankHolder" className="form-label">
                        Bank Account Holder Name
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="bankHolderName"
                        id="bankHolder"
                        aria-label="Bank Holder Name"
                        className={
                          hasError("bankHolderName")
                            ? "form-control  is-invalid"
                            : "form-control"
                        }
                        onChange={inputsHandler}
                        value={inputField.bankHolderName}
                        required
                        maxLength={50}
                      />
                      <span className="text-secondary">
                        Account holder name should be the legal name of the
                        organization or entity applying to receive DHCS funds.
                      </span>
                      <div className="invalid-feedback">
                        Enter account holder name
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="col-md-4 mt-4 ">
                        <label htmlFor="dropDownData" className="form-label">
                          Bank Account Type
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          name="bankAcctType"
                          id="dropDownData"
                          aria-label="Bank Account Type"
                          className={
                            hasError("bankAcctType")
                              ? "form-select me-5  is-invalid"
                              : "form-select me-5"
                          }
                          onChange={inputsHandler}
                          value={inputField.bankAcctType}
                          required
                        >
                          <option key="Select" value="Please Select">
                            Please Select
                          </option>
                          {Content.BankInfoPage.AccountType.map((ele) => {
                            return (
                              <option key={ele} value={ele}>
                                {ele}
                              </option>
                            );
                          })}
                        </select>

                        <div className="invalid-feedback">
                          Select account type
                        </div>
                      </div>
                    </div>

                    <div className="col-md-8 mt-4">
                      <span className="d-flex justify-content-between">
                        <label htmlFor="bankRouting" className="form-label">
                          Bank Routing Number
                          <span className="text-danger">*</span>
                        </label>
                        <button
                          type="button"
                          id="bankRouting"
                          aria-label="Bank Routing"
                          className="btn p-0 mb-1 m-0"
                          data-bs-container="body"
                          data-bs-toggle="popover"
                          data-bs-placement="right"
                          data-bs-content="Right popover"
                        >
                          <CheckPopup />
                        </button>
                      </span>
                      <input
                        type="text"
                        className={
                          hasError("bankRoutingNum")
                            ? "form-control  is-invalid"
                            : "form-control"
                        }
                        name="bankRoutingNum"
                        onChange={inputsHandler}
                        value={inputField.bankRoutingNum}
                        required
                        maxLength={9}
                      />
                      <span className="text-secondary">
                        Must be exactly 9 digits.
                      </span>

                      <div className="invalid-feedback">
                        Invalid routing number
                      </div>
                    </div>
                    <div className="col-md-8 mt-4">
                      <label htmlFor="reEnterBank" className="form-label">
                        Re-enter Bank Routing Number
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        id="reEnterBank"
                        aria-label="Reenter Bank Routing Number"
                        className={
                          hasError("reEnterBankRoutingNum")
                            ? "form-control me-5  is-invalid"
                            : "form-control me-5"
                        }
                        name="reEnterBankRoutingNum"
                        onChange={inputsHandler}
                        value={inputField.reEnterBankRoutingNum}
                        required
                        maxLength={9}
                      />
                      <div className="invalid-feedback">
                        Error, the Routing number doesn&apos;t match.
                      </div>
                    </div>

                    <div className="col-md-8 mt-4">
                      <span className="d-flex justify-content-between">
                        <label htmlFor="bankAcctNum" className="form-label">
                          Bank Account Number
                          <span className="text-danger">*</span>
                        </label>
                        <button
                          type="button"
                          id="bankAcctNum"
                          aria-label="Bank Account Number"
                          className="btn p-0 mb-1 m-0"
                          data-bs-container="body"
                          data-bs-toggle="popover"
                          data-bs-placement="right"
                          data-bs-content="Right popover"
                        >
                          <CheckPopup />
                        </button>
                      </span>

                      <input
                        type="text"
                        className={
                          hasError("bankAcctNum")
                            ? "form-control me-5  is-invalid"
                            : "form-control me-5"
                        }
                        name="bankAcctNum"
                        onChange={inputsHandler}
                        value={inputField.bankAcctNum}
                        required
                        maxLength={17}
                      />
                      <div className="invalid-feedback">
                        Invalid account number
                      </div>

                      <span className="text-secondary">
                        Must be 5 to 17 digits.
                      </span>
                    </div>
                    <div className="col-md-8 mt-4">
                      <label
                        htmlFor="reEnterBankAccountNum"
                        className="form-label"
                      >
                        Re-enter Bank Account Number
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={
                          hasError("reEnterBankAccountNum")
                            ? "form-control me-5  is-invalid"
                            : "form-control me-5"
                        }
                        name="reEnterBankAccountNum"
                        id="reEnterBankAccountNum"
                        aria-label="Reenter Bank Account Num"
                        onChange={inputsHandler}
                        value={inputField.reEnterBankAccountNum}
                        required
                        maxLength={17}
                      />
                      <div className="invalid-feedback">
                        Error, the Account number doesn&apos;t match.
                      </div>
                    </div>
                  </div>
                </form>
                <div className="col-12 mb-5">
                  <button
                    type="button"
                    aria-label="Previous Btn"
                    className="btn btn-pp-secondary-outline me-3 mt-4"
                    onClick={navigateBack}
                  >
                    Previous
                  </button>

                  <button
                    type="submit"
                    aria-label="Next Btn"
                    className="btn btn-pp-secondary mt-4"
                    onClick={saveInfo}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankingInfoPage;
