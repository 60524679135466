import { ReactComponent as DHCS_LOGO } from "../../Images/GlobalHeader/PATH Logo Black-White.svg";
import { useEffect, useState } from "react";
import "./index.css";

import { Auth } from "aws-amplify";
function GlobalHeader() {
  const [initials, setInitials] = useState("");

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        const fInitial = data.attributes.given_name.charAt(0);
        const lInitial = data.attributes.family_name.charAt(0);
        setInitials(fInitial + lInitial);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <nav
      className="navbar navbar-expand-lg  navbar-dark px-lg-4"
      id="globalNav"
    >
      <div className="container-fluid px-lg-5 py-lg-2">
        <button
          className="navbar-toggler p-2"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#bdSidebar"
          aria-controls="bdSidebar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <a aria-label="Go home" className="navbar-brand" href="/">
          <DHCS_LOGO />
        </a>

        {initials ? (
          <div className="dropdown d-flex flex-sm-row gap-2 mt-2 mt-md-0">
            <button
              className="fs-5 border-0 px-1 py-0"
              id="loggedUser"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {initials}
            </button>
            <ul className="dropdown-menu dropdown-menu-end p-1">
              <li>
                <button
                  className="dropdown-item text-primary"
                  onClick={() =>
                    Auth.signOut()
                      .then()
                      .catch((err) => console.error(err))
                  }
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </nav>
  );
}

export default GlobalHeader;
