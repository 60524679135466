export const WarningIcon = (props) => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.98151 1.06642C8.53763 0.311193 7.46546 0.311193 7.02158 1.06642L0.164626 12.7331C-0.292407 13.5107 0.256279 14.5 1.14459 14.5H14.8585C15.7468 14.5 16.2955 13.5107 15.8385 12.7331L8.98151 1.06642ZM8 4.5C8.53541 4.5 8.95377 4.96228 8.9005 5.49504L8.54975 9.00248C8.52151 9.28492 8.28384 9.5 8 9.5C7.71616 9.5 7.47849 9.28492 7.45025 9.00248L7.0995 5.49504C7.04623 4.96229 7.46459 4.5 8 4.5ZM8.00154 10.5C8.55383 10.5 9.00154 10.9477 9.00154 11.5C9.00154 12.0523 8.55383 12.5 8.00154 12.5C7.44926 12.5 7.00154 12.0523 7.00154 11.5C7.00154 10.9477 7.44926 10.5 8.00154 10.5Z"
        fill={props.color}
      />
    </svg>
  );
};
