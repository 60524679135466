import { useState } from "react";
import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import { Content } from "./Content";
import { useDispatch, useSelector } from "react-redux";
import { updateFileInfo } from "../../Redux/Slices/bankingInfo";
import { ReactComponent as DOWNLOAD_ICON } from "../../Images/GlobalHeader/DOWNLOAD_ICON.svg";
import MyDropzone from "../../Elements/Dropzone/Dropzone";
import "../../Styles/style.scss";
import Sidebar from "../../Components/Sidebar/Sidebar";
import CancelNavigation from "../../Components/CancelNavigation/CancelNavigation";
import { useNavigate } from "react-router-dom";
import { updateSidebarStatus } from "../../Redux/Slices/leftMenu";
import {
  FINANCE_BANK_INFO_URL,
  FINANCE_REVIEW_URL,
} from "../../Constants/Constants";
import useValidNavigation from "../../Elements/useValidNavigationHook/useValidNavigation";

const WFormUpload = () => {
  const menuNumber = 6;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Custom Hook to check if a user can access this page.
  useValidNavigation(FINANCE_BANK_INFO_URL, menuNumber);
  const { fileInfo } = useSelector((state) => state.bankingInfo);

  const [isPageUpdated, setIsPageUpdated] = useState("");
  const [showCancel, setshowCancel] = useState("");
  const [W9URL, setW9URL] = useState(fileInfo.w9fileURL);
  const [w9fileName, setw9fileName] = useState(fileInfo.w9fileName);

  const navigateBack = () => {
    setshowCancel(true);
    if (!isPageUpdated) {
      navigate(FINANCE_BANK_INFO_URL);
    }
  };

  // Save the W9 File as a URL
  const saveInfo = () => {
    if (W9URL) {
      dispatch(
        updateSidebarStatus({
          menuItem: menuNumber,
          isComplete: true,
        })
      );

      const updatedObj = {
        w9fileURL: W9URL,
        w9fileName,
      };

      dispatch(updateFileInfo(updatedObj));
      navigate(FINANCE_REVIEW_URL);
    }
  };

  return (
    <>
      {showCancel ? (
        <CancelNavigation
          navLink={FINANCE_BANK_INFO_URL}
          onCancelClick={() => setshowCancel(!showCancel)}
          menuItem={menuNumber}
        />
      ) : (
        <></>
      )}
      <GlobalHeader />
      <div className="d-flex flex-row">
        <div className="container-fluid ps-0 pe-5">
          <div className="row g-3 g-sm-0 d-flex justify-content-left">
            <div className="col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container grayBackground">
              <Sidebar activeIndex={menuNumber} />
            </div>
            <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight">
              <Breadcrumb innerPage="W-9 Form Upload" />
              <PageHeader
                title={Content.WFormUploadPage.title}
                subtitle={Content.WFormUploadPage.subtitle}
                isForm={true}
              />

              <div className="mb-3">
                {Content.WFormUploadPage.uploadTitle}
                <span className="text-danger">*</span>
              </div>
              <div className="blueCardBox col-10 pb-2">
                <p className="mb-0 link-primary ">
                  {Content.WFormUploadPage.uploadSubTitle}
                </p>
              </div>
              <div className="col-10 pt-3">
                <MyDropzone
                  setIsPageUpdated={setIsPageUpdated}
                  setW9URL={setW9URL}
                  setw9fileName={setw9fileName}
                  w9fileName={w9fileName}
                  W9URL={W9URL}
                />
              </div>
              <div className="mt-5">
                <a
                  aria-label="IRS Doc Link"
                  href="https://www.irs.gov/pub/irs-pdf/fw9.pdf"
                  target="_blank"
                  className="text-decoration-none"
                  rel="noreferrer"
                >
                  <DOWNLOAD_ICON /> Download IRS W-9
                </a>
                <div className="col-12 mb-5">
                  <button
                    type="button"
                    className="btn btn-pp-secondary-outline me-3 mt-4"
                    onClick={navigateBack}
                  >
                    Previous
                  </button>
                  <button
                    type="submit"
                    className={
                      W9URL
                        ? "btn btn-pp-secondary mt-4"
                        : "btn btn-pp-secondary mt-4 disabled"
                    }
                    onClick={saveInfo}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WFormUpload;
