import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import FindYourOrg from "./Pages/FindYourOrg/FindYourOrg";
import ConfirmOrg from "./Pages/ConfirmOrg/ConfirmOrg";
import IntroductionPage from "./Pages/IntroductionPage/IntroductionPage";
import Representative from "./Pages/Representative/Representative";
import SubmitPage from "./Pages/SubmitPage/SubmitPage";
import WFormUpload from "./Pages/WFormUpload/WFormUpload";
import TaxInfoPage from "./Pages/TaxInfoPage/TaxInfoPage";
import ReviewPage from "./Pages/ReviewPage/ReviewPage";
import BankingInfoPage from "./Pages/BankInfoPage/BankingInfoPage";
import EntityDetails from "./Pages/EntityDetails/EntityDetails";
import { Authenticator } from "@aws-amplify/ui-react";
import { RequireAuth } from "./Components/RequireAuth/RequireAuth";
import FinancialVerificationPage from "./Pages/FinancialVerifcationPage/FinancialVerificationPage";
import * as Constants from "./Constants/Constants";
import axios from "axios";
import { Auth } from "aws-amplify";
import { AmplifyLogin } from "./Pages/AmplifyLogin/AmplifyLogin";

function App() {
  axios.interceptors.request.use(async function (config) {
    const data = await Auth.currentSession()
      .then((data) => {
        return data.getIdToken().getJwtToken();
      })
      .catch(() => {
        return null;
      });
    config.headers.Authorization = data;
    return config;
  });
  return (
    <Authenticator.Provider>
      <Router>
        <Routes>
          <Route
            exact
            path={Constants.HOME_URL}
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.FIND_YOUR_ORG_URL}
            element={
              <RequireAuth>
                <FindYourOrg />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.CONFIRM_ORG_URL}
            element={
              <RequireAuth>
                <ConfirmOrg />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.FINANCE_VERIFICATION_URL}
            element={
              <RequireAuth>
                <FinancialVerificationPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.FINANCE_INTRODUCTION_URL}
            element={
              <RequireAuth>
                <IntroductionPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.FINANCE_REPRESENTATIVE_URL}
            element={
              <RequireAuth>
                <Representative />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.FINANCE_W9_UPLOAD_URL}
            element={
              <RequireAuth>
                <WFormUpload />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.FINANCE_TAX_INFO_URL}
            element={
              <RequireAuth>
                <TaxInfoPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.FINANCE_BANK_INFO_URL}
            element={
              <RequireAuth>
                <BankingInfoPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.FINANCE_SUBMIT_URL}
            element={
              <RequireAuth>
                <SubmitPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.FINANCE_REVIEW_URL}
            element={
              <RequireAuth>
                <ReviewPage />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.FINANCE_ENTITY_DETAIL_URL}
            element={
              <RequireAuth>
                <EntityDetails />
              </RequireAuth>
            }
          ></Route>
          <Route
            exact
            path={Constants.LOGIN_URL}
            element={<AmplifyLogin />}
          ></Route>
        </Routes>
      </Router>
    </Authenticator.Provider>
  );
}

export default App;
