import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: "idle",
  w9fileSize: null,
  formInfo: {
    firstName: "",
    lastName: "",
    title: "",
    phone: "",
    email: "",
    legalBizName: "",
    addressLine1: "",
    addressLine2: "",
    // transactionOne: "",
    // transactionTwo: "",
    city: "",
    state: "",
    zip: "",
    legalEntity: "",
    legalEntityOther: "",
    ssn: "",
    ein: "",
    bankName: "",
    bankHolderName: "",
    bankAcctType: "",
    bankRoutingNum: "",
    bankAcctNum: "",
    w9fileURL: null,
    esign: "",
  },
  fileInfo: {
    w9fileURL: null,
    w9fileName: null,
  },
};

const bankingInfoSlice = createSlice({
  name: "bankingInfo",
  initialState,
  reducers: {
    updateInfo: (state, action) => {
      state.formInfo = { ...state.formInfo, ...action.payload };
    },
    updateFileInfo: (state, action) => {
      state.fileInfo = { ...state.fileInfo, ...action.payload };
    },
    resetBankingInfo: () => initialState,
  },
  extraReducers: (builder) => {},
});

export const { updateInfo, updateFileInfo, resetBankingInfo } =
  bankingInfoSlice.actions;
export default bankingInfoSlice.reducer;
