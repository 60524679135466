import * as propTypes from "prop-types";

/**
 * PageHeader component to stylize the top of each protected page.
 *
 * Accepts the title and subtitle as well as a boolean isForm to display the "* indicates a required field"
 * @param title
 * @param subtitle
 * @param isForm
 * @returns {JSX.Element}
 * @constructor
 */
const PageHeader = ({ children, title, isForm }) => {
  return (
    <>
      <div className="d-flex justify-content-between">
        <h5 aria-hidden="true" className="text-pp-secondary">{title}</h5>
        {isForm ? (
          <p>
            <span className="text-danger">*</span>indicates a required field
          </p>
        ) : null}
      </div>
      <span className="text-dark fs-6">{children}</span>
    </>
  );
};

PageHeader.propTypes = {
  title: propTypes.any,
  isForm: propTypes.bool,
  children: propTypes.any,
};

PageHeader.defaultProps = {
  isForm: false,
};
PageHeader.displayName = "PageHeader";

export default PageHeader;
