export const LoadingState = () => {
  return (
    <div className="card">
      <div className="px-2 ms-1 pt-3">
        <p className="mb-3 text-dark">
          <div className="row px-3 pb-1 placeholder-glow">
            <span className="placeholder col-10"></span>
          </div>
        </p>
        <hr className="border-1 mb-2 me-auto"></hr>
        <div className="card-body p-0 placeholder-glow">
          <p>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-12"></span>
          </p>
        </div>
        <div className="placeholder-glow">
          <button className="btn col-8 placeholder mt-3 mb-3"></button>
        </div>
      </div>
    </div>
  );
};
