import { useSelector } from "react-redux";
import { Content } from "../TaxInfoPage/Content";
import { useState } from "react";
import { ReactComponent as SHOW_PASSWORD } from "../../Images/GlobalHeader/SHOW_PASSWORD.svg";
import { ReactComponent as HIDE_PASSWORD } from "../../Images/GlobalHeader/HIDE_PASSWORD.svg";

function ReviewTable() {
  const pageInfo = useSelector((state) => state.bankingInfo.formInfo);
  const [isPasswordShow, setIsPasswordShow] = useState([false, false]);
  // const [isSSNMismatch, setisSSNMismatch] = useState(false);

  const toggleIsPasswordShowValue = (item) => {
    const updatedToggle = isPasswordShow.map((ele, i) => {
      return i === item ? !ele : ele;
    });
    setIsPasswordShow(updatedToggle);
  };

  const taxType = Content.Options.find(
    (ele) => ele.code === pageInfo.legalEntity
  );
  // ssn masking
  const ssnMask = pageInfo.ssn;
  const endSSN = ssnMask.slice(0);
  const finalMaskSSN = endSSN.padStart(ssnMask.length, "*");

  // Bank Routing masking
  const routingMask = pageInfo.bankRoutingNum;
  const endRouting = routingMask.slice(-4);
  const finalRouting = endRouting.padStart(routingMask.length, "*");

  // Bank Account number masking
  const accountNum = pageInfo.bankAcctNum;
  const endAccountNum = accountNum.slice(-4);
  const finalAccountNum = endAccountNum.padStart(accountNum.length, "*");

  // EIN masking
  // const numEIN = pageInfo.ein;
  // const endEIN = numEIN.slice(-4);
  // const maskEIN = endEIN.padStart(numEIN.length, "*");

  return (
    <>
      <p className="fw-bold ps-3 mt-3">Representative Information</p>
      <table className="table table-striped table-borderless">
        <tbody>
          <tr>
            <td className="ps-3" scope="row">
              Name:
            </td>
            <td className="ps-3">
              {pageInfo.firstName} {pageInfo.lastName}
            </td>
          </tr>
          <tr>
            <td className="ps-3" scope="row">
              Title/Position:
            </td>
            <td className="ps-3">{pageInfo.title}</td>
          </tr>
          <tr>
            <td className="ps-3" scope="row">
              Email Address:
            </td>
            <td className="ps-3">{pageInfo.email}</td>
          </tr>
          <tr>
            <td className="ps-3" scope="row">
              Phone Number:
            </td>
            <td className="ps-3">{pageInfo.phone}</td>
          </tr>
        </tbody>

        <p className="fw-bold ps-3 mt-3">Entity Details</p>

        <tbody>
          <tr>
            <td className="ps-3" scope="row">
              Legal Business Name:
            </td>
            <td className="ps-3">{pageInfo.legalBizName}</td>
          </tr>
          <tr>
            <td className="ps-3" scope="row">
              Organization or Entity Address:
            </td>
            <td className="ps-3">
              {pageInfo.addressLine1}, {pageInfo.addressLine2} {pageInfo.city},{" "}
              {pageInfo.state} {pageInfo.zip}
            </td>
          </tr>
        </tbody>

        <p className="fw-bold ps-3 mt-3">Tax Information</p>

        <tbody>
          <tr>
            <td className="ps-3" scope="row">
              Legal Entity Type:
            </td>
            <td className="ps-3">{taxType?.description}</td>
          </tr>
          {taxType?.code === "OT" && (
            <tr>
              <td className="ps-3" scope="row">
                Other Value:
              </td>
              <td className="ps-3">{pageInfo.legalEntityOther}</td>
            </tr>
          )}

          {taxType?.type === "EIN" && (
            <tr>
              <td className="ps-3" scope="row">
                Employer Identification Number (EIN):
              </td>
              <td className="ps-3 d-flex">
                <div className="input-group" style={{ maxWidth: "13rem" }}>
                  <input
                    className="form-control"
                    type={isPasswordShow[0] ? "disable" : "password"}
                    value={pageInfo.ein}
                    readOnly
                  />
                  <div
                    className="input-group-text"
                    onClick={() => toggleIsPasswordShowValue(0)}
                  >
                    {isPasswordShow[0] ? <SHOW_PASSWORD /> : <HIDE_PASSWORD />}
                  </div>
                </div>
              </td>
            </tr>
          )}

          {taxType?.type === "SSN" && (
            <tr>
              <td className="ps-3" scope="row">
                Tax Identification Number (TIN):
              </td>
              <td className="ps-3 d-flex">
                <div className="input-group" style={{ maxWidth: "13rem" }}>
                  <input
                    className="form-control"
                    type={isPasswordShow[0] ? "disable" : "password"}
                    readOnly
                    value={finalMaskSSN}
                  />
                  <div
                    className="input-group-text"
                    onClick={() => toggleIsPasswordShowValue(0)}
                  >
                    {isPasswordShow[0] ? <SHOW_PASSWORD /> : <HIDE_PASSWORD />}
                  </div>
                </div>
              </td>
            </tr>
          )}
        </tbody>

        <p className="fw-bold ps-3 mt-3">Banking Information</p>

        <tbody>
          <tr>
            <td className="ps-3" scope="row">
              Name of Banking Institution:
            </td>
            <td className="ps-3">{pageInfo.bankName}</td>
          </tr>
          <tr>
            <td className="ps-3" scope="row">
              Bank Account Holder Name:
            </td>
            <td className="ps-3">{pageInfo.bankHolderName}</td>
          </tr>
          <tr>
            <td className="ps-3" scope="row">
              Bank Account Type:
            </td>
            <td className="ps-3">{pageInfo.bankAcctType}</td>
          </tr>
          <tr>
            <td className="ps-3" scope="row">
              Bank Routing Number:
            </td>
            <td className="ps-3 d-flex">{finalRouting}</td>
          </tr>
          <tr>
            <td className="ps-3 d-flex" scope="row">
              Bank Account Number:
            </td>
            <td className="ps-3">{finalAccountNum}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default ReviewTable;
