import { ReactComponent as Completed } from "../../Assets/LeftMenu/Completed.svg";
const SidebarElement = ({
  activeIndex,
  index,
  manageNavigation,
  icon: Icon,
  pageInfo,
  name,
  navlink,
}) => {
  const iconInactive = "#ADB5BD";
  const iconActive = "black";
  const isComplete = pageInfo.find((x) => x.menuItem === index)?.isComplete;

  // returns true or false
  const isActiveLink = activeIndex >= index || isComplete;

  return (
    <div
      onClick={() => manageNavigation(isActiveLink ? navlink : "#")}
      className="linkStyle"
    >
      <div
        className={activeIndex === index ? "row rowelement " : "row lightGray"}
      >
        <div className=" d-flex  noPadding  ">
          <div className={activeIndex === index ? "activetag" : ""}></div>

          <div className=" d-flex mt-3 mb-3 flex-fill  ">
            <div className="mx-2">
              <Icon color={isActiveLink ? iconActive : iconInactive} />
            </div>
            <div className={isActiveLink ? "" : "disabled"}>{name}</div>
            <div className="  ms-auto ">{isComplete && <Completed />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarElement;
