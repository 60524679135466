import * as PropTypes from "prop-types";

/**
 * Scroll Area Component utilizing Bootstrap 5 styling.
 *
 * Allows for the following overflow types:
 *  auto(default) | hidden | visible | scroll
 *
 *  Height and Width are required properties and must be passed as a number
 *    <ScrollArea ... height={200}.../>
 * @param children
 * @param className
 * @param type
 * @param height
 * @param width
 * @param restProps
 * @returns {JSX.Element}
 * @constructor
 */
const ScrollArea = ({
  children,
  className,
  type,
  height,
  width,
  ...restProps
}) => {
  const classes = "overflow" + (type ? "-" + type : "-auto") + " " + className;
  return (
    <div
      className={classes}
      {...restProps}
      style={{ maxHeight: height + "px", maxWidth: width + "px" }}
    >
      {children}
    </div>
  );
};

ScrollArea.propTypes = {
  children: PropTypes.any,
  classes: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["auto", "hidden", "visible", "scroll"]),
};

ScrollArea.displayName = "ScrollArea";

export default ScrollArea;
