import GlobalHeader from "../../Components/GlobalHeader/GlobalHeader";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import FindOrgSidebar from "../../Components/Sidebar/FindOrgSidebar";
import PageHeader from "../../Elements/PageHeader/PageHeader";
import { Content } from "./Content";
import { useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSidebarStatus } from "../../Redux/Slices/leftMenu";
import {
  EMAIL_VALIDATION,
  IS_NUMERIC_VALIDATION,
} from "../../Constants/Constants";
import VerifyingInfo from "./VerifyingInfo";

import { ErrorState } from "../../Components/ErrorComponent/ErrorState";
import { getUserOrgInfo } from "../../Redux/Slices/userInfo";

function FindYourOrg() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const localobj = {
    ApprovalCode: "",
    ApprovalZipCode: "",
    ApprovalEmail: "",
  };

  const { status, userStatus, userProfile } = useSelector(
    (state) => state.userInfo
  );

  const [errors, setErrors] = useState([]);
  const [inputField, setInputField] = useState(localobj);
  const [isPageUpdated, setIsPageUpdated] = useState("");
  const [isUnauthorized, setisUnauthorized] = useState(false);
  const [invalidOutcome, setinvalidOutcome] = useState("");

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  // Control the authorization of the page if accessed directly from URL
  useEffect(() => {
    if (userStatus !== "NoRecord") setisUnauthorized(false);
  }, []);

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    if (!isPageUpdated) {
      setIsPageUpdated(true);
      dispatch(
        updateSidebarStatus({
          menuItem: 2,
          isComplete: false,
        })
      );
    }
    if (name === "ApprovalZipCode") {
      if (!value.match(IS_NUMERIC_VALIDATION)) return;
    }

    setInputField({ ...inputField, [name]: value });
    if (errors.includes(name)) {
      setErrors(errors.filter((ele) => ele !== name));
    }
  };

  const formatNumber = (number) => {
    return number?.replace(/[^0-9\\.]+/g, "");
  };

  const formatApproval = (number) => {
    // Filter non numbers-
    const formattedNumber = number.replace(/[^0-9\\.]+/g, "");
    if (formattedNumber.length > 0) {
      if (formattedNumber.length < 4) {
        return formattedNumber;
      }
      if (formattedNumber.length > 4 && formattedNumber.length < 9) {
        return `${formattedNumber.slice(0, 4)} ${formattedNumber.slice(4)}`;
      }
      if (formattedNumber.length > 8 && formattedNumber.length < 13) {
        return `${formattedNumber.slice(0, 4)} ${formattedNumber.slice(
          4,
          8
        )} ${formattedNumber.slice(8, 12)}`;
      }

      if (formattedNumber.length > 12) {
        return `${formattedNumber.slice(0, 4)} ${formattedNumber.slice(
          4,
          8
        )} ${formattedNumber.slice(8, 12)} ${formattedNumber.slice(12, 15)}`;
      }
    }
    return formattedNumber;
  };

  const findInfo = () => {
    // Validations
    const errorOjb = [];

    if (
      inputField.ApprovalCode === "" ||
      formatNumber(inputField.ApprovalCode).length < 15
    ) {
      errorOjb.push("ApprovalCode");
    }

    if (inputField.ApprovalZipCode.length !== 5) {
      errorOjb.push("ApprovalZipCode");
    }

    if (!EMAIL_VALIDATION.test(inputField.ApprovalEmail)) {
      errorOjb.push("ApprovalEmail");
    }

    if (errorOjb.length > 0) {
      setErrors(errorOjb);
      //  return;
    }

    if (!errorOjb.length) {
      const apiObj = {
        pin: inputField.ApprovalCode.replace(/ /g, ""),
        email: inputField.ApprovalEmail,
        zip: inputField.ApprovalZipCode,
        username: userProfile.email,
      };

      dispatch(
        updateSidebarStatus({
          menuItem: 2,
          isComplete: true,
          validIndex: 3,
        })
      );
      getOrgObj(apiObj);
    }
  };

  const getOrgObj = async (apiObj) => {
    await dispatch(getUserOrgInfo(apiObj)).then((resp) => {
      if (resp.payload.orgName) {
        navigate("/confirmorg");
      } else if (
        resp.payload.toString().includes("Invalid Pin_Num/POC_Email/Zip_Code")
      ) {
        setinvalidOutcome(
          "The information entered is invalid. Please make sure you have entered the information correctly and try again."
        );
      } else if (
        resp.payload
          .toString()
          .includes("Not Authorized user " + userProfile?.email)
      ) {
        setinvalidOutcome(
          "The email must match the primary contacts email submitted in the application."
        );
      } else if (resp.payload.toString().includes("Pin already Linked")) {
        setinvalidOutcome(
          "The information entered is invalid. Please make sure you have entered the information correctly and try again."
        );
      } else if (resp.payload.toString().includes("Data not available")) {
        setinvalidOutcome(
          "The information entered is invalid. Please make sure you have entered the information correctly and try again."
        );
      } else {
        setinvalidOutcome(
          "The information entered is invalid. Please make sure you have entered the information correctly and try again."
        );
      }
    });
  };

  // Based on the states of the application the navigation will be to respective pages.
  const navigateBack = () => {
    navigate("/");
  };

  return (
    <>
      <GlobalHeader />
      <div className="d-flex flex-row">
        <div className="container-fluid ps-0 pe-3">
          <div className="row g-3 g-sm-0 d-flex justify-content-left">
            <div className="col-md-12 col-lg-2 gx-0 gx-sm-4 sidebar-container grayBackground">
              <FindOrgSidebar activeIndex={2} />
            </div>
            <div className="col-md-12 col-lg-9 gx-0 px-3 pt-2 gx-sm-4 minPageHeight ">
              {status === "loading" ? (
                <VerifyingInfo />
              ) : (
                <>
                  <Breadcrumb innerPage="Find Organization" />

                  {isUnauthorized ? (
                    <div> You are not authorized on this page</div>
                  ) : (
                    <>
                      <div className="container-fluid py-md-4">
                        <PageHeader
                          title={Content.EntityDetailsPage.title}
                          isForm={true}
                        >
                          {Content.EntityDetailsPage.subtitle}
                        </PageHeader>

                        <>
                          {invalidOutcome && (
                            <ErrorState message={invalidOutcome} />
                          )}

                          <>
                            <div className="col-md-12 col-lg-8 col-xl-5 gx-0  pt-2 gx-sm-4 mt-5">
                              <form className="row g-3 pe-0">
                                <div className="row">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="ApprovalCode"
                                      className="form-label"
                                    >
                                      Approval Letter PIN Code
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        hasError("ApprovalCode")
                                          ? "form-control  is-invalid"
                                          : "form-control"
                                      }
                                      name="ApprovalCode"
                                      id="ApprovalCode"
                                      aria-label="Approval Code"
                                      onChange={inputsHandler}
                                      value={formatApproval(
                                        inputField.ApprovalCode
                                      )}
                                      required
                                      maxLength={19}
                                    />

                                    <div className="invalid-feedback">
                                      Enter approval code
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="ApprovalEmail *"
                                      className="form-label"
                                    >
                                      Primary Contact Email
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        hasError("ApprovalEmail")
                                          ? "form-control  is-invalid"
                                          : "form-control"
                                      }
                                      name="ApprovalEmail"
                                      id="ApprovalEmail"
                                      aria-label="Approval Email"
                                      onChange={inputsHandler}
                                      value={inputField.ApprovalEmail}
                                      required
                                      maxLength={50}
                                    />
                                    <div className="invalid-feedback">
                                      Enter approval email
                                    </div>
                                  </div>
                                </div>

                                <div className="row col-4">
                                  <div className="mb-3 ">
                                    <label
                                      htmlFor="ApprovalZipCode"
                                      className="form-label"
                                    >
                                      Organization Zip
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className={
                                        hasError("ApprovalZipCode")
                                          ? "form-control  is-invalid"
                                          : "form-control"
                                      }
                                      name="ApprovalZipCode"
                                      id="ApprovalZipCode"
                                      aria-label="Approval Zipcode"
                                      onChange={inputsHandler}
                                      value={formatNumber(
                                        inputField.ApprovalZipCode
                                      )}
                                      required
                                      maxLength={5}
                                    />
                                    <div className="invalid-feedback">
                                      Enter zip code
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <div className="col-12 mb-5">
                                <button
                                  type="button"
                                  aria-label="Cancel Button"
                                  className="btn btn-pp-secondary-outline me-3 mt-4"
                                  onClick={navigateBack}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  aria-label="Find Button"
                                  className="btn btn-pp-secondary mt-4"
                                  onClick={findInfo}
                                >
                                  Find
                                </button>
                              </div>
                            </div>
                          </>
                        </>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FindYourOrg;
