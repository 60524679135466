export const HomeIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.35355 0.146447C7.15829 -0.0488155 6.84171 -0.0488155 6.64645 0.146447L0.646447 6.14645C0.552678 6.24021 0.5 6.36739 0.5 6.5V13.5C0.5 13.7761 0.723858 14 1 14H5.5C5.77614 14 6 13.7761 6 13.5V9.5H8V13.5C8 13.7761 8.22386 14 8.5 14H13C13.2761 14 13.5 13.7761 13.5 13.5V6.5C13.5 6.36739 13.4473 6.24021 13.3536 6.14645L12 4.79289V1.5C12 1.22386 11.7761 1 11.5 1H10.5C10.2239 1 10 1.22386 10 1.5V2.79289L7.35355 0.146447ZM1.5 13V6.70711L7 1.20711L12.5 6.70711V13H9V9C9 8.72386 8.77614 8.5 8.5 8.5H5.5C5.22386 8.5 5 8.72386 5 9V13H1.5Z"
        fill={props.color}
      />
    </svg>
  );
};
