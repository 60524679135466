export const LoadingState = () => {
  return (
    <>
      <>
        <button
          className="btn btn btn-pp-secondary mt-4"
          type="button"
          disabled
        >
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>{" "}
          Verifying
        </button>
      </>
    </>
  );
};
