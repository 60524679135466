/* eslint-disable  no-new */
import { ReactComponent as INFO_CIRCLE } from "../../Images/BankingInfoPage/INFO_CIRCLE.svg";
import Check from "../../Images/BankingInfoPage/Check.svg";
import { useEffect, useRef } from "react";
import { Popover } from "../../../node_modules/bootstrap/dist/js/bootstrap.esm.min";
import { renderToStaticMarkup } from "react-dom/server";
import "./CheckPopup.css";

/**
 * Function to return an INFO_CIRCLE that will display a popover
 * @returns {JSX.Element}
 * @constructor
 */
export const CheckPopup = () => {
  const popoverRef = useRef();
  /**
   *  Return the JSX as HTML preprocessed by the renderToStaticMarkup
   * @returns {string}
   */
  const content = () => {
    return renderToStaticMarkup(
      <div className="d-flex">
        <div>
          <p className="popupText">
            Bank Account Number Must be exactly X digits.
          </p>
          <p className="popupText">
            Bank Routing Number Must be exactly 9 digits.
          </p>
        </div>
        <img
          alt="check routing and account number example"
          src={Check}
          width="173"
          height="121"
        />
      </div>
    );
  };
  /**
   * On load create a new PopOver ref'ed by the popoverRef
   */
  useEffect(() => {
    new Popover(popoverRef.current, {
      content,
      title: "Account Information",
      trigger: "hover",
      html: true,
    });
  });
  return <INFO_CIRCLE ref={popoverRef} />;
};
