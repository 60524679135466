export const Content = {
  EntityDetailsPage: {
    title: "Find Your Organization",
    subtitle:
      "Please provide the three corresponding fields from the application and approval letter for verification and accurate association of financial information.",
  },
  Error:
    "We were unable to find your organization. Please try again or check for typos or unnecessary space. Please try again. For help contact ~emails or call on ~phone for assistance.",
  Contacts: [
    "cited@ca-path.com",
    "ta-marketplace@ca-path.com",
    "ji@ca-path.com",
    "collab@ca-path.com",
  ],
  Phone: "(866) 529-7550",
};
