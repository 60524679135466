export const Content = {
  SubmitPage: {
    title: "Submit",
    subtitle: "Please review and agree to the attestations before submitting.",
  },
  sucessMessage:
    "Your Bank Information has been successfully submitted. The confirmation number is: ",
  failureMessage:
    "We were unable to submit your Bank Information. For assistance in completing this form, please contact our team at:",
  contactEmail: "finance@ca-path.com",
  contactPhone: "or call us at (866) 529-7550.",
};
